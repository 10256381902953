import { NetworkStatus } from '@apollo/client';

export const isLoading = status => {
  return (
    status === NetworkStatus.loading || status === NetworkStatus.setVariables
  );
};

export const isReady = status => status === NetworkStatus.ready;
export const isFetchingMore = status => status === NetworkStatus.fetchMore;

export const getNetworkStatusDetails = status => {
  return {
    isReady: isReady(status),
    isLoading: isLoading(status),
    isFetchingMore: isFetchingMore(status),
  };
};

import React, { PropsWithChildren } from 'react';

export type CarouselProps = PropsWithChildren<any> & {
  data?: any[];
};

export const Carousel = ({ children }: CarouselProps) => {
  return (
    <div className="relative grid px-4 lg:px-0 pb-8 overflow-x-auto overflow-y-hidden lg:overflow-y-visible lg:overflow-x-visible w-100vw lg:w-auto overscroll-x-contain">
      {children}
    </div>
  );
};

import cn from 'classnames';
import React, { InputHTMLAttributes } from 'react';
import { Field } from 'formik';

export const FormRadio = ({
  children,
  name,
  variant = 'sm',
  className,
  ...rest
}: FormRadioProps) => {
  return (
    <label className={cn('flex items-center max-w-full', className)}>
      <Field
        name={name}
        type="radio"
        className={cn('focus:ring-powerblue text-powerblue border-gray-300', {
          'h-3 w-3': variant === 'xs',
          'h-4 w-4': variant === 'sm',
          'h-5 w-5': variant === 'md',
          'h-6 w-6': variant === 'lg',
        })}
        {...rest}
      />
      <span className="ml-2 overflow-hidden overflow-ellipsis">{children}</span>
    </label>
  );
};

export type FormRadioProps = InputHTMLAttributes<HTMLInputElement> & {
  variant?: 'xs' | 'sm' | 'md' | 'lg';
};

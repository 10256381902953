import { get } from 'lodash';
import { NextSeo } from 'next-seo';

import { seoConfig, titleTemplate } from '@wwtr/config';

type SeoProps = {
  title: string;
  description?: string;
};

const Seo = ({ title, description, ...props }: SeoProps) => (
  <NextSeo
    title={title || seoConfig.title}
    description={description || seoConfig.description}
    titleTemplate={title ? titleTemplate : undefined}
    openGraph={{
      ...seoConfig.openGraph,
      ...get(props, 'openGraph', {}),
      description:
        description ||
        get(props, 'openGraph.description', seoConfig.description),
    }}
    {...props}
  />
);

export default Seo;

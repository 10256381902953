/**
 * Google Analytics Tracking ID
 * @type {string}
 */
export const GA_TRACKING_ID = 'G-DBJHLMFBXM';

/**
 * Track pageview
 *
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 * @param url
 */
export const pageview = url => {
  // @ts-ignore
  window?.gtag &&
    // @ts-ignore
    window?.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
};

/**
 * Track event
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
export const event = ({
  action,
  category,
  label,
  value,
  silent = false,
}: {
  action: string;
  category: 'impressions' | 'views' | 'clicks' | 'interactions';
  label?: string;
  value?: number;
  silent?: boolean;
}) => {
  // @ts-ignore
  window?.gtag &&
    // @ts-ignore
    window?.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
      non_interaction: silent,
    });
};

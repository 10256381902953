import cn from 'classnames';
import React, { SelectHTMLAttributes } from 'react';
import { Field } from 'formik';

export const FormSelect = ({
  children,
  name,
  variant = 'sm',
  className,
  ...rest
}: FormSelectProps) => {
  return (
    <Field
      name={name}
      as="select"
      className={cn(
        'inline-block w-full rounded-md border-0 text-black focus:ring-2 focus:ring-powerblue',
        {
          'py-1 px-2 text-sm': variant === 'xs',
          'py-2 px-4': variant === 'sm',
          'py-4 px-4': variant === 'md',
          'py-5 px-6 text-xl': variant === 'lg',
        },
        className
      )}
      {...rest}
    >
      {children}
    </Field>
  );
};

export type FormSelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  variant?: 'xs' | 'sm' | 'md' | 'lg';
};

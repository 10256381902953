import { Field, Form, Formik } from 'formik';
import { FormInput, Button } from '@wwtr/components/ui';
import { SearchCircleIcon } from '@heroicons/react/outline';

export type GlobalSearchFormProps = {
  children?: React.ReactNode;
  className?: string;
};

const GlobalSearchForm = ({
  children,
  className,
  ...props
}: GlobalSearchFormProps) => {
  return (
    <Formik
      initialValues={{ age: '', gender: '', body: '' }}
      onSubmit={() => console.log('search!')}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form className="flex flex-row space-x-4">
          <label className="w-full">
            <span className="text-gray-400 sr-only">Je leeftijd</span>
            <Field
              name="q"
              type="text"
              maxLength="50"
              classNames={'w-full border-primary'}
              placeholder="Zoek op hulpaanbod of organisatie"
              size="md"
              component={FormInput}
            />
          </label>
          <div className="flex flex-col-reverse sm:flex-row justify-end">
            <Button
              type={'submit'}
              disabled={isSubmitting}
              className={'w-full sm:w-auto'}
              withIcon={true}
            >
              <SearchCircleIcon className="w-8 h-8 text-secondary" />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default GlobalSearchForm;

const colors = require('tailwindcss/colors');

module.exports = {
  purge: {
    content: [
      './src/components/**/*.{js,ts,jsx,tsx}',
      './src/pages/**/*.{js,ts,jsx,tsx}',
    ],
    safelist: [
      /^group-hover:(bg|text)-/,
      /^hover:(bg|text)-/,
      /^(bg|border|text)-/,
      /^active:bg-/,
    ],
  },
  theme: {
    fontFamily: {
      display: ['Rooney Sans', 'sans-serif'],
      body: [
        'Nunito Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'sans-serif',
      ],
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      white: colors.white,
      black: colors.black,
      red: colors.red,
      primary: {
        DEFAULT: '#642A7A',
        50: '#B775D0',
        100: '#B068CB',
        200: '#A24DC2',
        300: '#903CAF',
        400: '#7A3395',
        500: '#642A7A',
        600: '#542367',
        700: '#451D54',
        800: '#351641',
        900: '#26102E',
      },
      secondary: {
        DEFAULT: '#ABC408',
        50: '#EBFA89',
        100: '#E8F976',
        200: '#E1F84F',
        300: '#DBF627',
        400: '#CDEB0A',
        500: '#ABC408',
        600: '#96AB07',
        700: '#809306',
        800: '#6B7A05',
        900: '#556204',
      },
      hotpink: {
        DEFAULT: '#EB0089',
        50: '#FF76C6',
        100: '#FF66BF',
        200: '#FF48B3',
        300: '#FF29A6',
        400: '#FF0B99',
        500: '#EB0089',
        600: '#D2007A',
        700: '#B8006B',
        800: '#9F005C',
        900: '#85004E',
      },
      deeporange: {
        DEFAULT: '#F26E21',
        50: '#F7A778',
        100: '#F7A06E',
        200: '#F5945B',
        300: '#F48748',
        400: '#F37B34',
        500: '#F26E21',
        600: '#F1620E',
        700: '#DD5A0D',
        800: '#CA520C',
        900: '#B74A0B',
      },
      freshblue: {
        DEFAULT: '#24A8E0',
        50: '#74C8EB',
        100: '#6BC4EA',
        200: '#5ABDE8',
        300: '#48B6E5',
        400: '#36AFE3',
        500: '#24A8E0',
        600: '#1E9CD2',
        700: '#1B8FC0',
        800: '#1982AE',
        900: '#16749C',
      },
      powerblue: {
        DEFAULT: '#2A378D',
        50: '#4A5AC9',
        100: '#4253C7',
        200: '#3849BC',
        300: '#3343AC',
        400: '#2F3D9D',
        500: '#2A378D',
        600: '#25317D',
        700: '#212B6E',
        800: '#1C255E',
        900: '#171E4E',
      },
      gray: colors.coolGray,
    },
    extend: {
      maxWidth: theme => theme('maxHeight'),
      screens: {
        '3xl': '1920px',
        'can-hover': { raw: '(hover: hover)' },
        print: { raw: 'print' },
      },
      height: {
        '10vh': '10vh',
        '20vh': '20vh',
        '30vh': '30vh',
        '40vh': '40vh',
        '50vh': '50vh',
        '60vh': '60vh',
        '70vh': '70vh',
        '80vh': '80vh',
        '90vh': '90vh',
        '100vh': '100vh',
      },
      width: {
        '10vw': '10vw',
        '20vw': '20vw',
        '30vw': '30vw',
        '40vw': '40vw',
        '50vw': '50vw',
        '60vw': '60vw',
        '70vw': '70vw',
        '80vw': '80vw',
        '90vw': '90vw',
        '100vw': '100vw',
      },
      minHeight: {
        '10vh': '10vh',
        '20vh': '20vh',
        '30vh': '30vh',
        '40vh': '40vh',
        '50vh': '50vh',
        '60vh': '60vh',
        '70vh': '70vh',
        '80vh': '80vh',
        '90vh': '90vh',
        '100vh': '100vh',
      },
    },
  },
  variants: {
    extend: {
      opacity: ['disabled'],
      backgroundColor: ['active'],
      textColor: ['active'],
    },
    height: ({ after }) => after(['group-hover']),
    opacity: ({ after }) => after(['group-hover']),
    translate: ({ after }) => after(['group-hover']),
    scale: ({ after }) => after(['active']),
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('./src/libs/tailwindcss/currentBreakpoint'),
  ],
};

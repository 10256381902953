import cn from 'classnames';
import React, {
  ComponentProps,
  ElementType,
  forwardRef,
  PropsWithChildren,
} from 'react';

const Button = forwardRef<HTMLAnchorElement, ButtonProps>(
  (
    {
      children,
      href,
      as: Tag = 'button',
      className,
      disabled = false,
      isLoading = false,
      fullWidth = false,
      color,
      hoverColor,
      loadingText = 'Laden...',
      appearance = 'solid',
      withIcon,
      size = 'md',
      ...props
    },
    ref
  ) => {
    const content = (
      <>
        {!isLoading && children}
        {isLoading && <span>{loadingText}</span>}
      </>
    );

    const textColor = ['primary', 'hotpink', 'powerblue', 'black'].includes(
      color
    )
      ? 'white'
      : 'black';

    if (!hoverColor) {
      switch (color) {
        case 'white':
          hoverColor = 'gray-200';
          break;
        case 'secondary':
          hoverColor = 'secondary-600';
          break;
        default:
          hoverColor = `${color}-800`;
          break;
      }
    }

    return (
      <Tag
        href={href}
        ref={ref}
        disabled={disabled || isLoading}
        className={cn(
          {
            'inline-flex': !fullWidth,
            'flex w-full': fullWidth,
          },
          'items-center justify-center focus:outline-none focus:ring ring-powerblue-50 disabled:opacity-50 rounded-full whitespace-nowrap',
          {
            'space-x-2': withIcon,
          },
          {
            'cursor-wait': isLoading,
            'cursor-not-allowed': disabled,
          },
          {
            [`bg-${color} border-${color} text-${textColor} group-hover:bg-${hoverColor} hover:bg-${hoverColor} group-hover:border-${hoverColor} hover:border-${hoverColor} active:bg-${color} transition-colors`]:
              appearance === 'solid',
          },
          {
            [`border-${color} text-${color} group-hover:bg-${color} hover:bg-${color} group-hover:text-${textColor} hover:text-${textColor} active:bg-${hoverColor} active:text-${textColor} transition-colors`]:
              appearance === 'outline',
          },
          {
            'font-semibold border-2': appearance !== 'icon',
          },
          {
            'py-1 px-2 text-xs': size === 'xxs',
            'py-1 px-2 text-sm': size === 'xs',
            'py-2 px-4 text-base': size === 'sm',
            'py-3 px-6 text-lg': size === 'md',
            'py-5 px-8 text-2xl': size === 'lg',
          },
          className
        )}
        {...props}
      >
        {content}
      </Tag>
    );
  }
);

Button.displayName = 'Button';

export type ButtonProps = ComponentProps<'button'> &
  ComponentProps<'a'> &
  PropsWithChildren<{
    className?: string;
    disabled?: boolean;
    as?: ElementType;
    size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
    appearance?: 'outline' | 'solid' | 'icon';
    color?: string;
    hoverColor?: string;
    withIcon?: boolean;
    isLoading?: boolean;
    loadingText?: string;
    fullWidth?: boolean;
  }>;

export default Button;

import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

export type SearchFiltersFilterValue = {
  value: string;
  label: string;
};

export type SearchFiltersFilter = Map<string, SearchFiltersFilterValue[]>;

interface SearchFiltersContextInterface {
  filters: SearchFiltersFilter;
  addFilter: (
    name: string,
    value: string,
    label?: string,
    overwrite?: boolean
  ) => void;
  removeFilter: (name: string, value?: string) => void;
  resetFilters: () => void;
  getFilterValue: (name: string) => SearchFiltersFilterValue[];
  getFilterSingleValue: (name: string) => string;
}

const SearchFiltersContext =
  React.createContext<SearchFiltersContextInterface>(null);

type SearchFiltersProviderProps = PropsWithChildren<{}>;

const hasExistingValue = (
  values: SearchFiltersFilterValue[],
  match: string
): boolean => !!values.find(item => item.value === match);

export const SearchFiltersProvider = ({
  children,
}: SearchFiltersProviderProps) => {
  const [activeFilters, setActiveFilters] = useState(
    new Map<string, SearchFiltersFilterValue[]>()
  );

  const addFilter = useCallback(
    (name: string, value: string, label?: string, overwrite?: boolean) => {
      setActiveFilters(currentActiveFilters => {
        if (overwrite) {
          return new Map(
            currentActiveFilters.set(name, [
              {
                value,
                label,
              },
            ])
          );
        }

        if (currentActiveFilters.has(name)) {
          const currentItem = currentActiveFilters.get(name);

          if (hasExistingValue(currentItem, value)) {
            return new Map(currentActiveFilters);
          }

          return new Map(
            currentActiveFilters.set(name, [
              ...currentItem,
              {
                value,
                label,
              },
            ])
          );
        } else {
          return new Map(
            currentActiveFilters.set(name, [
              {
                value,
                label,
              },
            ])
          );
        }
      });
    },
    [activeFilters]
  );

  const removeFilter = useCallback(
    (name: string, value?: string) => {
      setActiveFilters(currentActiveFilters => {
        if (currentActiveFilters.has(name)) {
          const filterValue = currentActiveFilters.get(name);

          if (!value) {
            const copy = new Map(currentActiveFilters);
            copy.delete(name);

            return copy;
          } else {
            const newValue = filterValue.filter(item => item.value !== value);

            if (newValue.length > 0) {
              return new Map(
                currentActiveFilters.set(
                  name,
                  filterValue.filter(item => item.value !== value)
                )
              );
            } else {
              const copy = new Map(currentActiveFilters);
              copy.delete(name);

              return copy;
            }
          }
        } else {
          return currentActiveFilters;
        }
      });
    },
    [activeFilters]
  );

  const resetFilters = useCallback(() => {
    setActiveFilters(new Map());
  }, [activeFilters]);

  const getFilterValue = useCallback(
    (name: string) => activeFilters.get(name),
    [activeFilters]
  );

  const getFilterSingleValue = useCallback(
    (name: string): string => {
      return activeFilters.get(name)?.[0].value;
    },
    [activeFilters]
  );

  return (
    <SearchFiltersContext.Provider
      value={{
        filters: activeFilters,
        addFilter,
        removeFilter,
        resetFilters,
        getFilterValue,
        getFilterSingleValue,
      }}
    >
      {children}
    </SearchFiltersContext.Provider>
  );
};

export const useSearchFilters = () => useContext(SearchFiltersContext);

import { Fragment } from 'react';
import { OrganisationsSearchOptionsQuery } from '@wwtr/generated';
import { Dialog, Transition } from '@headlessui/react';

export type OrganizationsFiltersModalProps = {
  isLoading?: boolean;
  isOpen?: boolean;
  closeHandler(): any;
  data: OrganisationsSearchOptionsQuery;
};

export const OrganizationsFiltersModal = ({
  data,
  closeHandler,
  isOpen = false,
  isLoading = true,
}: OrganizationsFiltersModalProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        open={isOpen}
        onClose={closeHandler}
        className="fixed inset-0 z-50 overflow-y-auto"
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title>Dialog title</Dialog.Title>

              <div className="mt-2">
                <p className="text-sm text-gray-500">Lorem ipsum</p>
              </div>
              <Dialog.Description>Dialog description text</Dialog.Description>
              <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={closeHandler}
                >
                  Okay
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

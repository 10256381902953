import cn from 'classnames';
import React from 'react';

export const Skeleton = ({
  className,
  withCursor = false,
  opacity = 60,
  type = 'light',
}: SkeletonProps) => {
  return (
    <div className="animate-pulse">
      <div
        className={cn(
          'rounded',
          [`bg-opacity-${opacity}`],
          {
            'bg-gray-200': type === 'light',
            'bg-gray-800': type === 'dark',
          },
          {
            'cursor-loading': withCursor,
          },
          className
        )}
      ></div>
    </div>
  );
};

export type SkeletonProps = React.PropsWithChildren<{
  className: string | object | [];
  withCursor?: boolean;
  type?: 'light' | 'dark';
  opacity?: number;
}>;

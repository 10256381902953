import React, { PropsWithChildren, useCallback, useContext } from 'react';
import { useLocalStorage } from 'usehooks-ts';

export const FAVORITE_TYPE = {
  ORGANIZATION: 'organizations',
  FACILITY: 'facilities',
  ORGANIZATION_LOCATION: 'organizationLocations',
  FACILITY_LOCATION: 'facilityLocations',
  THEME: 'themes',
};

export type FavoritesStorage = {
  organizations?: string[];
  organizationLocations?: string[];
  facilities?: string[];
  facilityLocations?: string[];
  themes?: string[];
};

interface FavoritesContextInterface {
  favorites: FavoritesStorage;
  addFavorite: (key: string, id: string) => void;
  toggleFavorite: (key: string, id: string) => void;
  resetFavorites: () => void;
}

const FavoritesContext = React.createContext<FavoritesContextInterface>(null);

type FavoritesProps = PropsWithChildren<{}>;

export const FavoritesProvider = ({ children }: FavoritesProps) => {
  const [favorites, setFavorites] = useLocalStorage<FavoritesStorage>(
    'favorites',
    {
      organizations: [],
      organizationLocations: [],
      facilities: [],
      facilityLocations: [],
      themes: [],
    }
  );

  const addFavorite = useCallback(
    (key: keyof FavoritesStorage, id: string) => {
      setFavorites(current => ({
        ...current,
        [key]: [...current[key], id],
      }));
    },
    [favorites]
  );

  const toggleFavorite = useCallback(
    (key: keyof FavoritesStorage, id: string) => {
      setFavorites(current => {
        const newOrg = current[key]
          ? current[key].includes(id)
            ? current[key].filter(item => item !== id)
            : current[key].concat(id)
          : [id];

        return {
          ...current,
          [key]: newOrg,
        };
      });
    },
    [favorites]
  );

  const resetFavorites = useCallback(() => {
    setFavorites({});
  }, [favorites]);

  return (
    <FavoritesContext.Provider
      value={{
        favorites: favorites,
        addFavorite,
        toggleFavorite,
        resetFavorites,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};

export const useFavorites = () => useContext(FavoritesContext);

import React, { PropsWithChildren } from 'react';

import { SiteFooter, SiteHeader } from '@wwtr/components/ui';

type ViewportLayoutProps = PropsWithChildren<{}>;

export const ViewportLayout = ({ children }: ViewportLayoutProps) => {
  return (
    <>
      <SiteHeader />
      <main className="flex flex-col pt-24">{children}</main>
      <SiteFooter />
    </>
  );
};

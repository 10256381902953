import cn from 'classnames';
import { memo } from 'react';

export type ContainerProps = {
  children?: React.ReactNode;
  className?: string;
  fluid?: boolean;
  padded?: boolean;
  as?: string;
};

export const Container = memo(
  ({
    as,
    children,
    className,
    fluid = false,
    padded = true,
    ...props
  }: ContainerProps) => {
    const ContainerTag = as
      ? (`${as}` as keyof JSX.IntrinsicElements)
      : (`div` as keyof JSX.IntrinsicElements);

    return (
      <ContainerTag
        {...props}
        className={cn(
          { 'xl:container xl:mx-auto': !fluid },
          { 'px-4 lg:px-8': padded },
          className
        )}
      >
        {children}
      </ContainerTag>
    );
  }
);

Container.displayName = 'Container';

import cn from 'classnames';
import React, { InputHTMLAttributes } from 'react';
import { Field } from 'formik';

export const FormCheckbox = ({
  children,
  name,
  variant = 'md',
  className,
  ...rest
}: FormCheckboxProps) => {
  return (
    <div className={cn('leading-normal', className)}>
      <label
        className={cn(
          'inline-flex items-start max-w-full can-hover:cursor-pointer'
        )}
      >
        <Field
          name={name}
          type="checkbox"
          className={cn(
            ' border-2 focus:ring-powerblue text-powerblue border-gray-200 rounded-sm',
            {
              'h-4 w-5': variant === 'xs',
              'h-5 w-5': variant === 'sm',
              'h-6 w-6': variant === 'md',
              'h-7 w-7': variant === 'lg',
            }
          )}
          {...rest}
        />
        <span className="ml-2 overflow-hidden overflow-ellipsis">
          {children}
        </span>
      </label>
    </div>
  );
};

export type FormCheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  variant?: 'xs' | 'sm' | 'md' | 'lg';
};

import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export function useLocationHash() {
  const [hash, setHash] = useState<string>(null);
  const router = useRouter();

  useEffect(() => {
    const handleHashChange = hash => {
      setHash(hash.substring(2) || null);
    };

    if (window?.location?.hash) {
      setHash(window.location.hash.substring(1));
    }

    router.events.on('hashChangeStart', handleHashChange);

    return () => {
      router.events.off('hashChangeStart', handleHashChange);
    };
  }, []);

  return hash;
}

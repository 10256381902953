import useBreakpoint from 'use-breakpoint';
import { BREAKPOINTS } from '@wwtr/config';

export function useBreakpoints() {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'lg');

  const mobileBreakpoints = ['xs', 'sm'];
  const tabletPortraitBreakpoints = ['md'];
  const desktopBreakpoints = ['lg', 'xl', '2xl'];
  const wideScreenBreakpoints = ['xl', '2xl'];

  return {
    breakpoint: breakpoint,
    isMobile: mobileBreakpoints.includes(breakpoint),
    isTabletPortrait: tabletPortraitBreakpoints.includes(breakpoint),
    isDesktop: desktopBreakpoints.includes(breakpoint),
    isWideScreen: wideScreenBreakpoints.includes(breakpoint),
  };
}

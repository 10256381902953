import React from 'react';

import { DynamicRouterLinks } from '@wwtr/components/ui';
import styles from './ComponentBodyParagraph.module.scss';

export type ComponentBodyParagraphProps = {
  text: string;
};

export const ComponentBodyParagraph = ({
  text,
}: ComponentBodyParagraphProps) => {
  return (
    <DynamicRouterLinks>
      <div
        className={styles.root}
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
    </DynamicRouterLinks>
  );
};

import cn from 'classnames';
import { PropsWithChildren } from 'react';

export type TileProps = PropsWithChildren<{
  as?: string;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'secondary' | 'white';
  hasShadow?: boolean;
  padded?: boolean;
  hoverScale?: boolean;
}>;

const Tile = ({
  as = 'div',
  children,
  className,
  size,
  variant,
  hasShadow = false,
  hoverScale = false,
  padded = true,
  ...props
}: TileProps) => {
  const TagName = `${as}` as keyof JSX.IntrinsicElements;

  return (
    <TagName
      {...props}
      className={cn(
        'overflow-hidden',
        {
          'bg-primary text-white': variant === 'primary',
          'bg-secondary': variant === 'secondary',
          'bg-white': variant === 'white',
        },
        {
          'rounded-lg lg:rounded-xl': size === 'sm',
          'rounded-xl lg:rounded-2xl': size === 'md',
          'rounded-2xl lg:rounded-3xl': size === 'lg',
        },
        {
          'p-4': padded && size === 'sm',
          'p-4 md:p-6 lg:p-8': padded && size === 'md',
          'p-6 md:p-8 lg:p-16': padded && size === 'lg',
        },
        {
          'shadow-lg': hasShadow || hoverScale,
        },
        {
          'can-hover:transform can-hover:transition-all can-hover:duration-300 can-hover:scale-100 can-hover:hover:scale-105 can-hover:hover:shadow-2xl':
            hoverScale,
        },
        className
      )}
    >
      {children}
    </TagName>
  );
};

export default Tile;

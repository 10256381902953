import { ExternalLinkIcon } from '@heroicons/react/outline';

export const FormInputError = ({
  error = '',
  touched = false,
}: FormInputErrorProps) => {
  if (error && touched) {
    return (
      <div className="mt-2 text-sm text-red-500 inline-flex items-center justify-center space-x-2 leading-none">
        <ExternalLinkIcon className="h-8 w-8" />
        <span>{error}</span>
      </div>
    );
  }

  return null;
};

export type FormInputErrorProps = {
  error?: string;
  touched?: boolean;
};

import cn from 'classnames';
import React, { forwardRef, useEffect, useState } from 'react';
import { ArrowRightIcon } from '@heroicons/react/solid';
import StoryTail from '@wwtr/styles/storyTail';
import { Skeleton } from '../Skeleton';
import { useRouter } from 'next/router';
import { pathToRegexp } from 'path-to-regexp';

const randomWidthClass = () => {
  const possibleWidths = [
    'w-full',
    'w-2/3',
    'w-1/2',
    'w-1/4',
    'w-3/4',
    'w-2/5',
  ];
  return possibleWidths[Math.floor(Math.random() * possibleWidths.length)];
};

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      children,
      withArrow = false,
      color = 'powerblue',
      colorHover,
      customUnderline = false,
      containsIcon = false,
      isActive = false,
      isLoading = false,
      noWrap = true,
      iconSize,
      iconPosition = 'begin',
      matchHref,
      matchUrl = false,
      matchUrlExact = false,
      href,
      className,
      ...rest
    },
    ref
  ) => {
    const { asPath } = useRouter();
    if (matchUrl) {
      isActive = pathToRegexp(matchHref || href, [], {
        sensitive: true,
        end: !!matchUrlExact,
      }).test(asPath);
    }

    const [randomWidth, setRandomWidth] = useState('');

    useEffect(() => {
      setRandomWidth(randomWidthClass);
    }, []);

    if (isLoading) {
      return <Skeleton className={cn(randomWidth, 'my-1 h-4')} />;
    }

    return (
      <>
        <style jsx>{`
          @media (min-width: ${StoryTail.config.theme.screens.md}) {
            .custom-underline {
              position: relative;
            }
            .custom-underline::after {
              content: '';
              transition: all 0.3s;
              height: 2px;
              width: 0%;
              position: absolute;
              display: block;
              bottom: 0;
              left: ${containsIcon && iconSize && iconPosition === 'begin'
                ? StoryTail.config.theme.width[iconSize]
                : '0'};
              background: currentColor;
              visibility: hidden;
            }

            .custom-underline-active::after,
            .custom-underline:hover::after {
              width: ${!withArrow
                ? `calc(100% - ${
                    StoryTail.config.theme.width[iconSize] || '0%'
                  })`
                : `calc(100% - (${StoryTail.config.theme.width['6']}) - ${
                    StoryTail.config.theme.width[iconSize] || '0%'
                  })`};
              visibility: visible;
            }
          }
        `}</style>
        <a
          className={cn(
            `no-underline text-${color} cursor-pointer`,
            { 'inline-flex items-center space-x-2': containsIcon || withArrow },
            { 'max-w-full whitespace-nowrap': noWrap },
            { 'custom-underline': customUnderline },
            { 'custom-underline-active': customUnderline && isActive },
            { 'hover:underline': !customUnderline },
            { [`hover:text-${colorHover} transition-colors`]: !!colorHover },
            className
          )}
          href={href}
          ref={ref}
          {...rest}
        >
          {typeof children === 'string' && (
            <span
              className={cn(
                { 'overflow-hidden overflow-ellipsis': noWrap },
                { 'break-words': !noWrap }
              )}
            >
              {children}
            </span>
          )}
          {typeof children !== 'string' && children}
          {withArrow && <ArrowRightIcon className="w-4 flex-shrink-0" />}
        </a>
      </>
    );
  }
);

Link.displayName = 'Link';

export type LinkProps = React.ComponentPropsWithoutRef<'a'> & {
  color?: string;
  colorHover?: string;
  withArrow?: boolean;
  customUnderline?: boolean;
  containsIcon?: boolean;
  iconPosition?: 'begin' | 'end';
  iconSize?: number;
  isActive?: boolean;
  isLoading?: boolean;
  noWrap?: boolean;
  matchHref?: string;
  matchUrl?: boolean;
  matchUrlExact?: boolean;
};

import React, { useEffect, useRef } from 'react';
import lottie, { AnimationConfig } from 'lottie-web';

export type AnimatedSvgProps = {
  className?: string;
  options: Omit<AnimationConfig, 'container'>;
};

export const AnimatedSvg = ({ options, ...rest }: AnimatedSvgProps) => {
  const element = useRef<HTMLDivElement>(null);
  const lottieInstance = useRef<any>();

  useEffect(() => {
    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        ...options,
        container: element.current,
      });
    }
    return () => {
      lottieInstance.current?.destroy();
    };
  }, [options]);

  return <div ref={element} {...rest}></div>;
};

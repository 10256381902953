import React from 'react';
import { Section } from '../Section';
import { Container } from '../Container';
import {
  ThemeFacilityEntryFragment,
  ThemeOrganizationEntryFragment,
  Themes_Theme_Entry,
} from '@wwtr/generated';
import { ComponentBody } from '../ComponentBody';
import { Tile } from '../Tile';
import { Heading } from '../Heading';
import { Button } from '../Button';
import { HeartIcon } from '@heroicons/react/outline';
import NextLink from 'next/link';
import { Link } from '../Link';
import { ThemeOrganizations } from '../ThemeOrganizations';
import { getIcon } from '@wwtr/libs/utils';
import { FAVORITE_TYPE, useFavorites } from '@wwtr/libs/contexts';

export type ThemeDetailProps = {
  isLoading?: boolean;
  entry: Themes_Theme_Entry;
  facilities: ThemeFacilityEntryFragment[];
  organizations: ThemeOrganizationEntryFragment[];
};

export const ThemeDetail = ({
  isLoading = true,
  entry,
  facilities,
  organizations,
}: ThemeDetailProps) => {
  const { toggleFavorite, favorites } = useFavorites();

  const onClickFavorite = ($event, id: string) => {
    toggleFavorite(FAVORITE_TYPE.THEME, id);
  };

  const isFavorite = favorites?.themes?.includes(entry?.id);

  return (
    <>
      <Section>
        <Container>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 lg:col-span-8 xl:col-span-7">
              <div
                className="mb-8 text-2xl leading-snug lg:text-3xl lg:leading-snug text-gray-700"
                dangerouslySetInnerHTML={{
                  __html: entry?.lead,
                }}
              />
              <ComponentBody body={entry?.body} />
            </div>
            <div className="print:hidden hidden lg:block col-span-12 lg:col-span-4 xl:col-start-9">
              <div className="sticky top-32">
                <div className="mb-8">
                  <Button
                    appearance={isFavorite ? 'solid' : 'outline'}
                    color="hotpink"
                    fullWidth={true}
                    withIcon
                    className="mb-4"
                    onClick={$event => onClickFavorite($event, entry.id)}
                  >
                    <HeartIcon className="w-6" />
                    {isFavorite ? (
                      <span>Verwijder uit favorieten</span>
                    ) : (
                      <span>Opslaan in favorieten</span>
                    )}
                  </Button>
                </div>

                <Tile variant="white" size="md" hasShadow>
                  <Heading
                    level={3}
                    className="mb-4 font-light"
                    variant="gray-800"
                  >
                    Lees ook
                  </Heading>
                  {entry?.ancestors.flatMap(item => (
                    <ul key={`anchestor_${item.id}`}>
                      {item.children.map((sibling, index) => (
                        <li className="py-2" key={`sibling_${sibling.id}`}>
                          <NextLink href={`/${sibling.uri}`} passHref>
                            <Link customUnderline withArrow>
                              {sibling.title}
                            </Link>
                          </NextLink>
                        </li>
                      ))}
                    </ul>
                  ))}
                </Tile>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section as="aside" className="relative">
        <div className="flex justify-center mb-8">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <figure className="bg-white p-8 rounded-full">
            <img alt={entry.title} src={getIcon(entry)} className="w-12" />
          </figure>
        </div>
        <ThemeOrganizations
          entry={entry}
          organizations={organizations}
          facilities={facilities}
        />
      </Section>
    </>
  );
};

import {
  Component,
  ComponentType,
  PropsWithChildren,
  PropsWithRef,
} from 'react';

interface FallbackProps {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}

type ErrorBoundaryProps = {
  FallbackComponent: ComponentType<FallbackProps>;
  onReset?: (...args: Array<unknown>) => void;
  onError?: (error: Error, info: { componentStack: string }) => void;
};

type ErrorBoundaryState = { error: Error | null };

const initialState: ErrorBoundaryState = { error: null };

export class ErrorBoundary extends Component<
  PropsWithRef<PropsWithChildren<ErrorBoundaryProps>>,
  ErrorBoundaryState
> {
  state = initialState;

  static getDerivedStateFromError(error) {
    return { error };
  }

  resetErrorBoundary = (...args: Array<unknown>) => {
    this.props.onReset?.(...args);
    this.reset();
  };

  reset() {
    this.setState(initialState);
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    // console.log({ error, errorInfo });
  }

  render() {
    const { error } = this.state;

    const { FallbackComponent } = this.props;

    // Check if the error is thrown
    if (error !== null) {
      const props = {
        error,
        resetErrorBoundary: this.resetErrorBoundary,
      };

      return <FallbackComponent {...props} />;

      /*// You can render any custom fallback UI
      return (
        <div>
          <h2>Oops, there is an error!</h2>
          <button
            type="button"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </button>
        </div>
      );*/
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

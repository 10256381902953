import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

export type SectionProps = PropsWithChildren<{
  as?: string;
  className?: string;
}>;

export const Section = ({ as, children, className }: SectionProps) => {
  const SectionTag = as
    ? (`${as}` as keyof JSX.IntrinsicElements)
    : (`section` as keyof JSX.IntrinsicElements);
  return (
    <SectionTag
      className={cn(
        'py-10 sm:py-12 md:py-14 lg:py-16 xl:py-18 2xl:py-20',
        className
      )}
    >
      {children}
    </SectionTag>
  );
};

import NextLink from 'next/link';
import { Link } from '@wwtr/components/ui';
import React from 'react';
import { useBreakpoints } from '@wwtr/libs/hooks';
import { Themes_Theme_Entry } from '@wwtr/generated';

const splitToChunks = (array, parts) => {
  let result = [];
  for (let i = parts; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)));
  }
  return result;
};

export type PopularThemesProps = {
  items: Themes_Theme_Entry[];
  isLoading: boolean;
};

export const PopularThemes = ({ items, isLoading }: PopularThemesProps) => {
  const { isTabletPortrait, isDesktop, isWideScreen } = useBreakpoints();

  let linksList;

  if (items) {
    linksList = [...items];
  } else {
    linksList = Array(20).fill(0);
  }

  let linkColumns;
  if (isWideScreen) {
    linkColumns = 4;
  } else if (isTabletPortrait || isDesktop) {
    linkColumns = 3;
  } else {
    linkColumns = 2;
  }

  const splittedGroups = splitToChunks(linksList, linkColumns);

  return (
    <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-8">
      {splittedGroups.map((group, groupIndex) => (
        <ul key={`group_${groupIndex}`}>
          {group.map((link, index) => (
            <li className="py-2" key={`group_${groupIndex}_link_${index}`}>
              <NextLink href={link.uri || ''}>
                <Link
                  href={link.uri || ''}
                  withArrow
                  customUnderline
                  isLoading={isLoading}
                >
                  {link.title}
                </Link>
              </NextLink>
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};

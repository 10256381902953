import NextLink from 'next/link';
import { Link } from '@wwtr/components/ui';
import React, { memo } from 'react';
import { SearchIcon } from '@heroicons/react/solid';
import { HeartIcon } from '@heroicons/react/outline';

export type SiteNavProps = {
  onSearch: () => void;
};

const menuItems = [
  {
    title: 'Onderwerpen',
    url: '/een-vraag-over/',
    exactMatch: false,
  },
  {
    title: 'Zoek in uw regio',
    url: '/zoek/organisaties/',
    matchHref: '/zoek/(organisaties|aanbod)',
    exactMatch: false,
  },
  /*{
    title: 'Hulpaanbod',
    url: '/aanbod/',
    exactMatch: false,
  },*/
];

export const SiteNavComponent = ({ onSearch }: SiteNavProps) => {
  return (
    <nav className="h-full flex items-center">
      <ul className="w-full flex">
        {menuItems.map((item, index) => {
          return (
            <li
              className={index > 0 ? 'ml-6 xl:ml-8' : ''}
              key={`main_menu_${index}`}
            >
              <NextLink href={item.url}>
                <Link
                  href={item.url}
                  color="gray-500"
                  colorHover="gray-700"
                  customUnderline
                  matchHref={item.matchHref}
                  matchUrl
                  matchUrlExact={item.exactMatch}
                >
                  {item.title}
                </Link>
              </NextLink>
            </li>
          );
        })}
        <li className={'ml-auto'}>
          <NextLink href="/favorieten">
            <Link
              href="/favorieten"
              containsIcon
              customUnderline
              iconSize={8}
              color="gray-700"
            >
              <HeartIcon className="w-6 text-hotpink" />
              <span>Favorieten</span>
            </Link>
          </NextLink>
        </li>
        <li className={'ml-6'}>
          <Link
            href="#"
            color="gray-700"
            containsIcon
            customUnderline
            iconSize={8}
            iconPosition="begin"
            onClick={onSearch}
          >
            <SearchIcon className="h-6 w-6 fill-white" />
            <span>Zoeken</span>
          </Link>
        </li>
      </ul>
      <h2 className="sr-only">Hoofdmenu</h2>
    </nav>
  );
};

export const SiteNav = memo(SiteNavComponent);

import React from 'react';

import { ComponentBodyParagraph } from '../ComponentBodyParagraph';
import { ComponentBodySlideShow } from '../ComponentBodySlideShow';

import {
  Body_SlideShowBlock_BlockType,
  Body_TextBlock_BlockType,
} from '@wwtr/generated';

const COMPONENT_TYPE_MAPPING = {
  textBlock: ComponentBodyParagraph,
  slideShowBlock: ComponentBodySlideShow,
};

const SUPPORTED_TYPES = ['textBlock'];

export type BodyBlockType =
  | Body_SlideShowBlock_BlockType
  | Body_TextBlock_BlockType;

export type ComponentBodyProps = {
  body: BodyBlockType[];
};

export const ComponentBody = ({ body = [] }: ComponentBodyProps) => {
  return (
    <div className="space-y-6">
      {body &&
        body.map((component, index) => {
          if (!SUPPORTED_TYPES.includes(component.typeHandle)) {
            return null;
          }

          const ComponentTag = COMPONENT_TYPE_MAPPING[component.typeHandle];

          return (
            <ComponentTag
              key={`body-component-${component.id}-${index}`}
              {...component}
            />
          );
        })}
    </div>
  );
};

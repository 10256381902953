import React, { useCallback, useEffect, useState } from 'react';

import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Container,
  Heading,
} from '@wwtr/components/ui';
import { PrinterIcon, ShareIcon } from '@heroicons/react/solid';

const PageHeader = ({
  className,
  title,
  breadCrumbs = [],
  isLoading = true,
}: PageHeaderProps) => {
  const [pageURL, setPageURL] = useState('');
  const [canNativeShare, setCanNativeShare] = useState(false);
  useEffect(() => {
    setPageURL(window.location.href);
    if (navigator.share) {
      setCanNativeShare(true);
    }
  }, []);

  const share = useCallback(async () => {
    try {
      await navigator?.share({
        title: title,
        text: 'Lees meer op Wegwijs Twenterand.',
        url: document.location.toString(),
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [title, pageURL]);

  return (
    <header className="mb-8 md:mb-16">
      <Container>
        <div className="pt-4 pb-4 md:pt-8 sm:pb-6 flex flex-col lg:flex-row-reverse lg:items-center">
          <div className="self-end lg:self-auto flex flex-row print:hidden mb-2 lg:mb-0">
            <Button
              appearance="outline"
              size="xxs"
              color="powerblue"
              withIcon
              onClick={() => window.print()}
            >
              <PrinterIcon className="w-4 h-4" />
              <span>Print</span>
            </Button>
            {canNativeShare && (
              <Button
                appearance="outline"
                size="xxs"
                color="powerblue"
                withIcon
                onClick={share}
                className="ml-2"
              >
                <ShareIcon className="w-4 h-4" />
                <span>Deel</span>
              </Button>
            )}
          </div>
          <Heading
            variant="primary"
            level={1}
            isLoading={isLoading}
            className="flex-grow font-bold"
          >
            {title}
          </Heading>
        </div>
        {breadCrumbs.length > 0 && (
          <nav role="navigation" aria-label="breadcrumbs">
            <h2 className="sr-only">Breadcrumbs</h2>
            <Breadcrumbs items={breadCrumbs} isLoading={isLoading} />
          </nav>
        )}
      </Container>
    </header>
  );
};

export type PageHeaderProps = {
  breadCrumbs?: BreadcrumbsItem[];
  className?: string;
  isLoading?: boolean;
  pageTitle?: string;
  title: string;
};

export default PageHeader;

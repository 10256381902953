import {
  META_DESCRIPTION,
  META_KEYWORDS,
  SITE_TITLE,
  TITLE_SEPARATOR,
} from './constants.config';

export const titleTemplate = `%s${TITLE_SEPARATOR}${SITE_TITLE}`;

export const seoConfig = {
  title: SITE_TITLE,
  keywords: META_KEYWORDS,
  description: META_DESCRIPTION,
  openGraph: {
    description: META_DESCRIPTION,
    type: 'website',
    locale: 'nl_NL',
    url: 'https://wegwijstwenterand.nl/',
    site_name: SITE_TITLE,
    images: [
      {
        url: 'https://www.wegwijstwenterand.nl/logo-icon.jpg',
        width: 1000,
        height: 839,
        alt: 'Wegwijstwenterand.nl',
      },
    ],
  },
};

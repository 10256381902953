import React, { LabelHTMLAttributes, PropsWithChildren } from 'react';
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline';
import { useField } from 'formik';
import cn from 'classnames';

const Error = ({ error }: ErrorProps) => (
  <div className="flex items-center text-sm text-white leading-relaxed bg-red-600 rounded-full py-1 px-2">
    <ExclamationCircleIcon className="w-6 h-6 mr-2" />
    <span>{error}</span>
  </div>
);

type ErrorProps = {
  error?: string;
  touched?: boolean;
};

const Label = ({
  label,
  variant = 'dark',
  htmlFor,
  required = false,
}: LabelProps) => (
  <label
    className={cn('block mb-1 text-lg font-semibold', {
      'text-white': variant === 'light',
      'text-black': variant === 'dark',
    })}
    htmlFor={htmlFor}
  >
    {label}
    {!required && (
      <span
        className={cn(
          'text-opacity-20 text-sm font-semibold leading-normal ml-1',
          {
            'text-white': variant === 'light',
            'text-black': variant === 'dark',
          }
        )}
      >
        (optioneel)
      </span>
    )}
  </label>
);

type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  label: string;
  required?: boolean;
  variant?: 'light' | 'dark';
};

const Help = ({ text }: HelpProps) => (
  <div className="flex items-center text-white text-sm mb-1 leading-relaxed px-2">
    <InformationCircleIcon className="w-6 h-6 mr-2" />
    <div>{text}</div>
  </div>
);

type HelpProps = {
  text: string;
};

const FormFieldWrapper = ({ children }: FormFieldWrapperProps) => (
  <div className="w-full space-y-2">{children}</div>
);

type FormFieldWrapperProps = PropsWithChildren<{}>;

const FormInputWrapper = ({ className, children }: FormInputWrapperProps) => (
  <div className={className}>{children}</div>
);

type FormInputWrapperProps = PropsWithChildren<{
  className?: string;
}>;

export const FormField = ({
  children,
  className,
  name,
  label,
  htmlFor,
  help,
  required = true,
  ...rest
}: FormFieldProps) => {
  const [input, meta] = useField(name);

  const { touched, error } = meta || {};

  return (
    <FormFieldWrapper>
      {label && <Label label={label} htmlFor={htmlFor} required={required} />}
      <FormInputWrapper className={className}>{children}</FormInputWrapper>
      {help && <Help text={help} />}
      {touched && error && <Error error={error} />}
    </FormFieldWrapper>
  );
};

export type FormFieldProps = PropsWithChildren<{
  name: string;
  label?: string;
  htmlFor?: string;
  className?: string;
  required?: boolean;
  help?: string;
  onChange?: (change: any) => void;
  onFocus?: (change: any) => void;
  onBlur?: (change: any) => void;
}>;

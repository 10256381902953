import Link from 'next/link';
import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';

import { clip } from '@wwtr/libs/utils';

export type BreadcrumbProps = {
  children: React.ReactNode;
  href: string;
  shorten?: boolean;
  reverse?: boolean;
};

const Breadcrumb = ({
  children,
  href,
  shorten = false,
  reverse = false,
}: BreadcrumbProps) => {
  return href ? (
    <>
      {reverse && (
        <ChevronLeft size={14} className="inline text-gray-400 mr-1" />
      )}
      <Link href={href}>
        <a className="text-sm md:text-base whitespace-nowrap text-gray-500 hover:underline focus:outline-none focus:underline">
          {shorten ? clip(children) : children}
        </a>
      </Link>
      {!reverse && (
        <ChevronRight size={14} className="inline text-gray-400 mx-1" />
      )}
    </>
  ) : (
    <span className="text-sm md:text-base whitespace-nowrap text-gray-400">
      {shorten ? clip(children) : children}
    </span>
  );
};

export default Breadcrumb;

import React, { useEffect, useState } from 'react';

import { hasCookie, setCookie } from 'cookies-next';
import cn from 'classnames';
import NextLink from 'next/link';
import { Link } from '../Link';
import { Button } from '../Button';
import { Tile } from '../Tile';

export const ONE_YEAR = 60 * 60 * 24 * 365;
export const CONSENT_COOKIE_NAME = 'cookieConsent';

export const CookieConsent = ({}: CookieConsentProps) => {
  const [consent, setConsent] = useState(true);

  useEffect(() => {
    setConsent(hasCookie(CONSENT_COOKIE_NAME));
  }, []);

  const acceptCookie = () => {
    setConsent(true);
    setCookie(CONSENT_COOKIE_NAME, 'true', { maxAge: ONE_YEAR });
    gtag('consent', 'update', {
      ad_storage: 'denied',
      analytics_storage: 'granted',
    });
    console.log('accepting cookies');
  };

  const denyCookie = () => {
    setConsent(true);
    setCookie(CONSENT_COOKIE_NAME, 'false', { maxAge: ONE_YEAR });
  };

  if (consent === true) {
    return null;
  }

  return (
    <Tile
      variant="white"
      className={cn(
        'fixed bottom-4 left-4 right-4 lg:left-auto z-50 md:text-xl md:w-50vw lg:w-40vw lg:w-30vw 3xl:w-20vw',
        {
          hidden: consent,
        }
      )}
      padded
      size="md"
      hasShadow={true}
    >
      <p className="mb-8">
        Wij gebruiken cookies enkel voor statistieken. Je leest er alles over in
        het{' '}
        <NextLink href={'/privacy-en-cookieverklaring'} passHref>
          <Link>privacy beleid</Link>
        </NextLink>
        .
      </p>
      <div className="flex flex-wrap justify-end items-center space-x-4">
        <Link color="powerblue" href="#" onClick={e => denyCookie()}>
          Liever niet
        </Link>
        <Button
          appearance="solid"
          color="secondary"
          onClick={() => {
            acceptCookie();
          }}
          className="p-2 bg-green-400 rounded-md"
        >
          Ja, toestaan
        </Button>
      </div>
    </Tile>
  );
};

export type CookieConsentProps = {};

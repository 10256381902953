/**
 * Utility to get the Tailwind config as a Javascript object. Used for
 * Storybook
 *
 */
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfigFile from '../../tailwind.config';

class StoryTail {
  config;

  constructor() {
    this.config = resolveConfig(tailwindConfigFile);
  }

  get fontSizes() {
    return Object.entries(this.config.theme.fontSize).map(fontSize => {
      return fontSize[1][0];
    });
  }
}

export default new StoryTail();

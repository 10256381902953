import cn from 'classnames';
import React from 'react';

import { Breadcrumb } from '@wwtr/components/ui';
import { useBreakpoints } from '@wwtr/libs/hooks';

export const Breadcrumbs = ({
  items = [],
  isLoading = false,
  className,
}: BreadcrumbsProps) => {
  const { isMobile } = useBreakpoints();

  const breadCrumbs = [
    {
      url: '/',
      label: 'Home',
      shorten: false,
    },
    ...items,
  ];

  const renderItems = isMobile ? breadCrumbs.slice(-2, -1) : breadCrumbs;

  if (isLoading) {
    return (
      <div className="flex space-x-4 animate-pulse py-1 mb-4">
        <div className="h-3 bg-gray-200 rounded w-3/12 md:w-1/12"></div>
        <div className="h-3 bg-gray-200 rounded w-3/12 md:w-1/12"></div>
      </div>
    );
  } else {
    return (
      <ol className={cn('flex', className)}>
        {renderItems &&
          renderItems.map((item, index) => (
            <li
              key={`breadcrumb-${index}`}
              className="inline-flex items-center"
            >
              <Breadcrumb
                href={item.url}
                shorten={item.shorten}
                reverse={isMobile}
              >
                {item.label}
              </Breadcrumb>
            </li>
          ))}
      </ol>
    );
  }
};

export type BreadcrumbsItem = {
  url?: string;
  label: string;
  shorten?: boolean;
};

export type BreadcrumbsProps = {
  className?: string;
  clip?: boolean;
  isLoading?: boolean;
  items: BreadcrumbsItem[];
};

import { Container, Link, Logo, Skeleton } from '@wwtr/components/ui';
import NextLink from 'next/link';
import React from 'react';
import { useQuery } from '@apollo/client';
import {
  GlobalDataQuery,
  GlobalFooterFragment,
  GlobalInfoFragment,
} from '@wwtr/generated';
import GLOBAL_QUERY from '@wwtr/gql/queries/global.gql';
import { getNetworkStatusDetails } from '@wwtr/libs/apollo';

const SiteFooter = () => {
  const { data, networkStatus } = useQuery<GlobalDataQuery>(GLOBAL_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const { isLoading, isReady } = getNetworkStatusDetails(networkStatus);

  const footer = data?.footer as GlobalFooterFragment;
  const info = data?.info as GlobalInfoFragment;

  return (
    <>
      {/*language=CSS*/}
      <style jsx>
        {`
          .SiteFooter {
            background-image: url(/assets/images/body-background-transparent.svg);
            background-position: center top;
          }
        `}
      </style>
      <footer role="contentinfo" className="SiteFooter bg-gray-900">
        <h2 className="sr-only">Footer</h2>
        <Container className="text-gray-400">
          <div className="grid grid-cols-1 lg:grid-cols-6 gap-8 py-16 leading-relaxed border-b border-white border-opacity-10">
            <div className="print:hidden text-gray-200">
              <Logo className="max-w-44 lg:max-w-none" />
            </div>
            <div className="lg:col-span-3 flex flex-col space-y-6">
              {isLoading ? (
                <div className="flex flex-col space-y-4 mt-2">
                  <Skeleton className="w-full h-3" />
                  <Skeleton className="w-4/5 h-3" />
                  <Skeleton className="w-full h-3" />
                  <Skeleton className="w-full h-3" />
                  <Skeleton className="w-1/3 h-3" />
                </div>
              ) : (
                <p>{footer?.description}</p>
              )}
            </div>
            {isLoading ? (
              <div className="lg:col-span-2 flex flex-col space-y-4 mt-2">
                <Skeleton className="w-1/3 h-3" />
                <Skeleton className="w-4/5 h-3" />
                <Skeleton className="w-full h-3 mt-4" />
                <Skeleton className="w-full h-3" />
                <Skeleton className="w-4/5 h-3" />
                <Skeleton className="w-1/3 h-3 mt-4" />
                <Skeleton className="w-1/2 h-3" />
                <Skeleton className="w-4/5 h-3" />
              </div>
            ) : (
              <div className="lg:col-span-2 flex flex-col space-y-6">
                <p>
                  Neem contact op met
                  <br />
                  <span className="text-lg text-gray-200" itemProp="telephone">
                    {info?.phone}
                  </span>
                </p>
                <address
                  itemProp="address"
                  itemScope
                  itemType="http://schema.org/PostalAddress"
                >
                  <span>Postadres</span>
                  <span className="text-gray-200">
                    <br />
                    <span itemProp="streetAddress">{info?.address}</span>
                    <br />
                    <span itemProp="postalCode">{info?.postalCode}</span>{' '}
                    <span itemProp="addressLocality">{info?.city}</span>
                  </span>
                </address>
                <p>
                  <Link
                    href={`mailto:${info?.email}`}
                    itemProp="email"
                    color="gray-200"
                  >
                    {info?.email}
                  </Link>
                </p>
                <ul className="print:hidden inline-flex flex-col">
                  {footer?.menu.map((item, index) => (
                    <li key={`footer_global_link_${index}`}>
                      <NextLink href={item.url} passHref>
                        <Link color="gray-200" className="inline">
                          {item.title}
                        </Link>
                      </NextLink>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="py-8" id="copyright">
            Copyright &copy; {new Date().getFullYear()} Wegwijs Twenterand. Alle
            rechten voorbehouden.
          </div>
        </Container>
      </footer>
    </>
  );
};

export default SiteFooter;

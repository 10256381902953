import cn from 'classnames';
import React, { InputHTMLAttributes } from 'react';

export const FormInput = ({
  variant = 'sm',
  className,
  ...props
}: FormInputProps) => {
  return (
    <input
      type="text"
      {...props}
      className={cn(
        'w-full rounded-md border-2 border-gray-200 text-black focus:ring-0 focus:border-powerblue',
        {
          'py-1 px-2 text-sm': variant === 'xs',
          'py-2 px-4': variant === 'sm',
          'py-4 px-4': variant === 'md',
          'py-5 px-6 text-xl': variant === 'lg',
        },
        className
      )}
    />
  );
};

export type FormInputProps = InputHTMLAttributes<HTMLInputElement> & {
  variant?: 'xs' | 'sm' | 'md' | 'lg';
};

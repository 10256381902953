import { SearchIcon } from '@heroicons/react/solid';
import React, { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useFormik } from 'formik';

export type SiteNavSearchProps = {
  onBlur?: () => void;
  onSubmit?: () => void;
};

const SiteNavSearch = ({ onBlur, onSubmit }: SiteNavSearchProps) => {
  const inputElement = useRef(null);

  const escFunction = useCallback(event => {
    if (event.keyCode === 27) {
      inputElement.current.blur();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  const router = useRouter();

  const searchForm = useFormik({
    initialValues: {
      query: '',
    },
    onSubmit: values => {
      if (typeof onSubmit === 'function') {
        onSubmit();
      }

      router.push(
        { pathname: '/zoek/organisaties', query: { q: values.query } },
        null,
        {
          shallow: false,
        }
      );
    },
  });

  return (
    <div className="flex h-full items-center">
      <div className="relative flex-grow text-gray-600">
        <form onSubmit={searchForm.handleSubmit}>
          <input
            type="search"
            onBlur={onBlur}
            name="query"
            onChange={searchForm.handleChange}
            value={searchForm.values.query}
            placeholder="Zoek in het aanbod van organisaties en hulpaanbod"
            className="w-full bg-white h-14 px-5 pr-10 rounded-full focus:outline-none"
            onFocus={e => e.currentTarget.select()}
            ref={inputElement}
          />
          <button
            type="submit"
            className="absolute right-1 top-0 p-4 focus:outline-none focus:text-powerblue"
            aria-label="Start zoekopdracht"
          >
            <SearchIcon className="h-6 w-6 fill-current" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default SiteNavSearch;

import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

type DimmerContextProps = {
  isDimmed: boolean;
  toggleDimmed: () => void;
};

type DimmerProviderProps = PropsWithChildren<{
  initialState: boolean;
}>;

const DimmerContext = React.createContext<DimmerContextProps>({
  isDimmed: false,
  toggleDimmed: () => {},
});

export const DimmerProvider = ({
  initialState = false,
  children,
}: DimmerProviderProps) => {
  const [isDimmed, setIsDimmed] = useState(initialState);

  const toggleDimmed = useCallback(() => setIsDimmed(!isDimmed), [isDimmed]);

  return (
    <DimmerContext.Provider
      value={{
        isDimmed: isDimmed,
        toggleDimmed: toggleDimmed,
      }}
    >
      {children}
    </DimmerContext.Provider>
  );
};

export const useDimmer = () => useContext(DimmerContext);

import { Transition } from '@headlessui/react';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { useWindowWidth } from '@react-hook/window-size';
import { useQuery } from '@apollo/client';
import { groupBy, throttle } from 'lodash';
import { Themes_Theme_Entry, ThemesIndexQuery } from '@wwtr/generated';
import THEMES_INDEX_QUERY from '@wwtr/gql/queries/themes-index.gql';
import { getNetworkStatusDetails } from '@wwtr/libs/apollo/utils';
import { useLocationHash } from '@wwtr/libs/hooks';
import { useRouter } from 'next/router';

export type CategoryNavigatorProps = {
  className?: string;
};

enum DIRECTION {
  BACKWARDS,
  FORWARDS,
}

const isCategory = (item): boolean => {
  return Array.isArray(item.items);
};

const getParent = (
  entries: any[],
  item: Themes_Theme_Entry
): Themes_Theme_Entry => {
  return entries.find(entry => entry.id === item.parent?.id);
};

const isParent = (
  entries: Themes_Theme_Entry[],
  entry: Themes_Theme_Entry
): boolean => {
  return entries.some(item => item.parent?.id === entry.id);
};

const getIcon = (
  entries: Themes_Theme_Entry[],
  item: Themes_Theme_Entry
): string => {
  if (item.icon.url !== null) return item.icon.url;

  if (isParent(entries, item)) {
    return '/assets/images/open-folder.svg';
  } else {
    return '/assets/images/file.svg';
  }
};

const groupByParent = entries => {
  return Object.values(
    groupBy(entries, entry => {
      return entry.parent?.id ? entry.parent.id : 0;
    })
  );
};

const CategoryNavigator = ({ className }: CategoryNavigatorProps) => {
  const [currentPageSlug, setCurrentPageSlug] = useState<string>('');
  const [direction, setDirection] = useState<DIRECTION>(DIRECTION.FORWARDS);
  const [containerHeight, setContainerHeight] = useState(0);
  const [minContainerHeight, setMinContainerHeight] = useState(0);

  const windowWidth = useWindowWidth();
  const hash = useLocationHash();
  const router = useRouter();
  const currentPageRef = useRef<HTMLDivElement | null>(null);
  const backButtonRef = useRef<null | HTMLAnchorElement>(null);
  const firstButtonRef = useRef<null | HTMLAnchorElement>(null);

  useEffect(() => {
    setCurrentPageSlug(decodeURIComponent(hash || 'start'));
  }, [hash]);

  const { data: themesIndexData, networkStatus: themesIndexNetworkStatus } =
    useQuery<ThemesIndexQuery>(THEMES_INDEX_QUERY, {
      notifyOnNetworkStatusChange: true,
    });

  const { isLoading: themesIndexIsLoading, isReady: themesIndexIsReady } =
    getNetworkStatusDetails(themesIndexNetworkStatus);

  const onEnterComplete = throttle(
    () => {
      const node = currentPageRef?.current;

      if (node) {
        setTimeout(() => {
          const { height } = node.getBoundingClientRect();

          // Only update if we don't have a value yet
          if (
            themesIndexIsReady &&
            minContainerHeight === 0 &&
            currentPageSlug === 'start'
          ) {
            setMinContainerHeight(height);
          }

          setContainerHeight(height);

          if (backButtonRef?.current) {
            backButtonRef.current?.focus();
          }
        }, 500);
      }
    },
    100,
    { leading: false }
  );

  useEffect(() => {
    // Reset
    setMinContainerHeight(0);
  }, [windowWidth]);

  useEffect(() => {
    onEnterComplete();
  }, [minContainerHeight]);

  const navigateToPageId = (
    id: Themes_Theme_Entry['id'],
    slug: Themes_Theme_Entry['slug'],
    direction: DIRECTION
  ): Promise<boolean> => {
    setDirection(direction);

    return router.push(
      {
        hash: slug ? slug : null,
      },
      null,
      { scroll: false }
    );
  };

  const goUp = (event, entries: any[], entry: Themes_Theme_Entry): void => {
    event.preventDefault();

    const parent = getParent(entries, entry);
    const parentParent = getParent(entries, parent);

    navigateToPageId(
      parentParent?.id || null,
      parentParent?.slug,
      DIRECTION.BACKWARDS
    );
  };

  const goDeeper = (event, entry: Themes_Theme_Entry): Promise<boolean> => {
    event.preventDefault();

    if (isParent(themesIndexData.entries as Themes_Theme_Entry[], entry)) {
      return navigateToPageId(entry.id, entry.slug, DIRECTION.FORWARDS);
    }

    return router.push({
      pathname: entry.uri,
    });
  };

  const transitionProps = useMemo(() => {
    if (direction === DIRECTION.FORWARDS) {
      return {
        enterFrom: 'transform opacity-0 scale-75',
        enterTo: 'transform opacity-100 scale-100',
        leaveFrom: 'transform opacity-100 scale-100',
        leaveTo: 'transform opacity-0 scale-125',
      };
    } else {
      return {
        enterFrom: 'transform opacity-0 scale-125',
        enterTo: 'transform opacity-100 scale-100',
        leaveFrom: 'transform opacity-100 scale-100',
        leaveTo: 'transform opacity-0 scale-75',
      };
    }
  }, [direction]);

  const pages = themesIndexIsLoading
    ? []
    : groupByParent(themesIndexData?.entries);

  return (
    <div
      className="relative transition-all duration-200"
      style={{
        height: `${containerHeight}px`,
        minHeight: `${minContainerHeight || 400}px`,
      }}
    >
      <Transition.Root
        show={themesIndexIsLoading}
        key={`page_loading`}
        afterEnter={onEnterComplete}
      >
        <div
          className="absolute top-0 w-full grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-8"
          ref={currentPageRef}
          id="page_holder_loading"
        >
          {Array.from({ length: 12 }, (_, key) => (
            <Transition.Child
              enter={`transition ease-out duration-200`}
              enterFrom={transitionProps.enterFrom}
              enterTo={transitionProps.enterTo}
              leave="transition ease-out duration-200"
              leaveFrom={transitionProps.leaveFrom}
              leaveTo={transitionProps.leaveTo}
              key={`loading_theme_${key}`}
              className="animate-pulse flex flex-row h-full sm:flex-col space-x-4 sm:space-x-0 sm:space-y-4 p-4 md:p-8 w-full sm:justify-center items-center bg-white rounded-2xl shadow-xl hover:shadow-2xl border-white border-4 hover:border-gray-900"
            >
              <div className="h-16 w-16 bg-gray-200 rounded"></div>
              <div className="space-y-3 w-full flex flex-col items-center">
                <div className="h-4 w-4/5 bg-gray-200 rounded"></div>
              </div>
            </Transition.Child>
          ))}
        </div>
      </Transition.Root>

      {themesIndexIsReady &&
        pages.map(page => {
          const parentEntry = getParent(themesIndexData.entries, page[0]);
          const pageSlug = parentEntry?.slug || 'start';

          return (
            <Transition.Root
              show={pageSlug === currentPageSlug}
              key={`page_${pageSlug}`}
              id={`page_${pageSlug}`}
              appear={true}
              afterEnter={onEnterComplete}
            >
              <div
                className="absolute top-0 w-full grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-8"
                id={`page_holder_${pageSlug}`}
                ref={currentPageRef}
              >
                {pageSlug !== 'start' && (
                  <Transition.Child
                    enter={`transition ease-out duration-200`}
                    enterFrom={transitionProps.enterFrom}
                    enterTo={transitionProps.enterTo}
                    leave="transition ease-out duration-200"
                    leaveFrom={transitionProps.leaveFrom}
                    leaveTo={transitionProps.leaveTo}
                    key={`subItem_return_${page[0].parent?.parent?.id})}`}
                    as="a"
                    href={`#`}
                    ref={backButtonRef}
                    onClick={e => goUp(e, themesIndexData.entries, page[0])}
                    className="flex flex-row h-full sm:flex-col space-x-4 sm:space-x-0 sm:space-y-2 p-4 md:p-8 w-full sm:justify-center items-center bg-white rounded-2xl shadow-xl can-hover:hover:shadow-2xl border-white border-4 can-hover:hover:border-gray-900"
                  >
                    <ArrowLeftIcon className="w-10" />
                    <h3 className="text-lg font-bold sm:text-center">Terug</h3>
                  </Transition.Child>
                )}

                {page.map((item, subIndex) => (
                  <Transition.Child
                    enter={`transition ease-out duration-200`}
                    enterFrom={transitionProps.enterFrom}
                    enterTo={transitionProps.enterTo}
                    leave="transition ease-out duration-200"
                    leaveFrom={transitionProps.leaveFrom}
                    leaveTo={transitionProps.leaveTo}
                    key={`subItem_${subIndex}`}
                    as="a"
                    ref={
                      pageSlug === 'start' && subIndex === 0
                        ? firstButtonRef
                        : null
                    }
                    href={`#${item.slug}`}
                    onClick={e => goDeeper(e, item)}
                    className="flex flex-row h-full sm:flex-col space-x-4 sm:space-x-0 sm:space-y-2 p-4 md:p-8 w-full sm:justify-center items-center bg-white rounded-2xl shadow-xl can-hover:hover:shadow-2xl border-white border-4 can-hover:hover:border-gray-900"
                  >
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      src={getIcon(
                        themesIndexData.entries as Themes_Theme_Entry[],
                        item
                      )}
                      className="w-16"
                      alt={item.title}
                    />
                    <h3 className="text-lg lg:text-xl font-bold sm:text-center">
                      {item.title}
                    </h3>
                  </Transition.Child>
                ))}
              </div>
            </Transition.Root>
          );
        })}
    </div>
  );
};

export default memo(CategoryNavigator);

import cn from 'classnames';
import React, { PropsWithChildren } from 'react';
import { Button } from '@wwtr/components/ui';
import { XIcon } from '@heroicons/react/solid';

export const FilterTerm = ({
  children,
  className,
  onClick,
}: FilterTermProps) => {
  return (
    <label
      className={cn(
        'flex flex-row items-center py-1 border-2 border-white pl-3 pr-1 text-sm font-semibold rounded-full whitespace-nowrap bg-white focus:outline-none focus:ring ring-powerblue-50 shadow-md',
        className
      )}
    >
      <span className="mr-2">{children}</span>
      <Button
        color="gray-400"
        hoverColor="gray-800"
        appearance="solid"
        size="xxs"
        onClick={onClick}
      >
        <span className="sr-only">Verwijder {children}</span>
        <XIcon className="w-4 text-white" />
      </Button>
    </label>
  );
};

export type FilterTermProps = PropsWithChildren<{
  className?: string;
  onClick?: (change: any) => void;
}>;

import { forwardRef } from 'react';

export type ThemeTileProps = {
  className?: string;
  icon?: string;
  title?: string;
  onClick?: any;
  href?: string;
  isLoading?: boolean;
};

const ThemeTile = ({
  className,
  icon,
  title,
  onClick,
  href,
  isLoading = false,
  ...props
}: ThemeTileProps) => {
  if (isLoading) {
    return (
      <div className="flex flex-row h-full sm:flex-col space-x-4 sm:space-x-0 sm:space-y-2 p-4 md:p-8 w-full sm:justify-center items-center bg-white rounded-2xl shadow-xl hover:shadow-2xl border-white border-4 hover:border-gray-900">
        <div className="w-16 h-16" />
        <div className="w-full h-8">Loading</div>
      </div>
    );
  }

  return (
    <a
      href={href}
      onClick={onClick}
      className="flex flex-row h-full sm:flex-col space-x-4 sm:space-x-0 sm:space-y-2 p-4 md:p-8 w-full sm:justify-center items-center bg-white rounded-2xl shadow-xl hover:shadow-2xl border-white border-4 hover:border-gray-900"
    >
      <img src={icon} className="w-16" />
      <h3 className="text-lg lg:text-xl font-bold sm:text-center">{title}</h3>
    </a>
  );
};
export default ThemeTile;

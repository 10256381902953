import React, { ComponentType, PropsWithChildren } from 'react';

const getValidChildren = (children: React.ReactNode) => {
  return React.Children.toArray(children).filter(child =>
    React.isValidElement(child)
  ) as React.ReactElement[];
};

export type ListLimitMoreComponentProps = PropsWithChildren<{
  uri?: string;
  total?: number;
}>;

export const ListLimit = ({
  children,
  total,
  limit,
  flexible,
  showMoreComponent,
  showMoreUri,
  ...props
}: ListLimitProps) => {
  let isLimited = false;
  const count = React.Children.count(children);

  const items = React.Children.toArray(children);

  if (count >= limit + flexible) {
    items.length = limit - 1;
    isLimited = true;
  }

  const MoreComponent = showMoreComponent;

  return (
    <ul {...props}>
      {items.map((child, index) => (
        <li
          key={`list_limit_${index}`}
          className="w-80vw sm:w-60vw md:w-40vw lg:w-auto"
        >
          {child}
        </li>
      ))}
      {isLimited && (
        <li>
          <MoreComponent uri={showMoreUri} total={total - limit} />
        </li>
      )}
    </ul>
  );
};

export type ListLimitProps = PropsWithChildren<{
  className?: string;
  showMoreUri?: string;
  total?: number;
  limit?: number;
  flexible?: number;
  showMoreComponent?: ComponentType<ListLimitMoreComponentProps>;
}>;

import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

let apolloClient: ApolloClient<NormalizedCacheObject> = null;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      ssrLog(
        `[GraphQL Error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) ssrLog(`[GraphQL Error] Network error: ${networkError}`);
});

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_API_ENDPOINT_URL,
  headers: {
    authorization: 'Bearer ' + process.env.NEXT_PUBLIC_API_ENDPOINT_TOKEN,
  },
});

const retryIf = (error, operation) => {
  const doNotRetryCodes = [500, 400];
  const shouldRetry = !!error && !doNotRetryCodes.includes(error.statusCode);

  if (shouldRetry) {
    // eslint-disable-next-line no-console
    ssrLog(
      `[GraphQL Retry] Got error ${error.statusCode} on ${
        operation.operationName
      }(${JSON.stringify(operation.variables)}), retrying...`
    );
  }

  return shouldRetry;
};

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf,
  },
});

const loggerLink = new ApolloLink((operation, forward) => {
  ssrLog(
    `[GraphQL Logger] start request: ${
      operation.operationName
    }(${JSON.stringify(operation.variables)})`
  );
  operation.setContext({ start: new Date() });
  return forward(operation).map(response => {
    const responseTime = new Date().getTime() - operation.getContext().start;
    ssrLog(
      `[GraphQL Logger] end request ${operation.operationName}(${JSON.stringify(
        operation.variables
      )}) (${responseTime}ms)`
    );
    return response;
  });
});

const batchLink = new BatchHttpLink({
  uri: process.env.NEXT_PUBLIC_API_ENDPOINT_URL,
  headers: {
    authorization: 'Bearer ' + process.env.NEXT_PUBLIC_API_ENDPOINT_TOKEN,
  },
  batchMax: 5, // No more than 5 operations per batch
  batchInterval: 200, // Wait no more than 200ms after first batched operation
});

/**
 * See this setup: https://medium.com/@joanvila/productionizing-apollo-links-4cdc11d278eb
 */
const links = ApolloLink.from([loggerLink, retryLink, errorLink, batchLink]);

const createApolloClient = (options: DefaultOptions = {}) =>
  new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: links,
    cache: new InMemoryCache(),
    defaultOptions: options,
  });

export function initializeApolloClient(
  initialState: NormalizedCacheObject | null = null
) {
  const client = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = client.extract();

    // Restore the cache with the merged data
    client.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return client;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = client;
  }

  return client;
}

const ssrLog = (message: string) => {
  if (typeof window === 'undefined') {
    console.log(message);
  }
};

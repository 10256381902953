import cn from 'classnames';
import React, { useEffect } from 'react';
import { CountUp } from 'use-count-up';
import { Skeleton } from '../Skeleton';

const DefaultWrapper: React.FunctionComponent = ({ children }) => (
  <span>{children}</span>
);

export const Cardinal = ({
  isLoading = false,
  TextWrapper = DefaultWrapper,
  CountWrapper = DefaultWrapper,
  text,
  count = 0,
  duration = 100,
  isAnimated = true,
  onComplete,
}: CardinalProps) => {
  if (isLoading) {
    return (
      <div className="space-y-3">
        <Skeleton className="w-16 h-12" />
        <Skeleton className="w-32 h-4" />
      </div>
    );
  }

  return (
    <div>
      <div className="text-gray-700 text-3xl text-opacity-80">
        <CountWrapper>
          {isAnimated && (
            <CountUp
              isCounting
              onComplete={onComplete}
              end={count}
              duration={duration}
            />
          )}
          {!isAnimated && <span>{count}</span>}
        </CountWrapper>
      </div>
      <TextWrapper>
        <div className="text-gray-700 text-opacity-60">{text}</div>
      </TextWrapper>
    </div>
  );
};

export type CardinalProps = {
  variant?:
    | 'primary'
    | 'secondary'
    | 'black'
    | 'white'
    | 'hotpink'
    | 'deeporange'
    | 'freshblue'
    | 'powerblue';
  text?: string;
  count?: number;
  duration?: number;
  isLoading?: boolean;
  isAnimated?: boolean;
  onComplete?: (args?: any) => void;
  CountWrapper?: React.ElementType;
  TextWrapper?: React.ElementType;
};

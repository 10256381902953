import React, { PropsWithChildren, useContext, useState } from 'react';

type MobileMenuContextProps = {
  isMenuOpen: boolean;
  toggleMenu: () => void;
};

type MobileMenuProviderProps = PropsWithChildren<{
  initialState: boolean;
}>;

const MobileMenuContext = React.createContext<MobileMenuContextProps>({
  isMenuOpen: false,
  toggleMenu: () => {},
});

export const MobileMenuProvider = ({
  initialState = false,
  children,
}: MobileMenuProviderProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(initialState);

  return (
    <MobileMenuContext.Provider
      value={{
        isMenuOpen: isMenuOpen,
        toggleMenu: () => setIsMenuOpen(!isMenuOpen),
      }}
    >
      {children}
    </MobileMenuContext.Provider>
  );
};

export const useMobileMenu = () => useContext(MobileMenuContext);

import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FormInputError } from '@wwtr/components/ui';

const FormTextarea = ({ field, form, extraClassNames, ...props }) => {
  const { touched, errors } = form;

  return (
    <>
      <textarea
        className={cn(
          'mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary',
          extraClassNames
        )}
        rows="4"
        {...field}
        {...props}
      />
      <FormInputError
        error={errors[field.name]}
        touched={touched[field.name]}
      />
    </>
  );
};

FormTextarea.propTypes = {
  extraClassNames: PropTypes.any,
  field: PropTypes.object,
  form: PropTypes.object,
};

export default FormTextarea;

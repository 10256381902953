import React from 'react';

export type SpinnerProps = React.ComponentPropsWithoutRef<'svg'> & {
  radius?: number;
  thickness?: number;
};

export const Spinner = ({
  radius = 20,
  thickness = 2,
  ...props
}: SpinnerProps) => {
  return (
    <>
      <style jsx>
        {`
          @keyframes spinner {
            0% {
              transform: rotate(0deg);
              stroke-dashoffset: ${0.66 * radius};
            }
            50% {
              transform: rotate(720deg);
              stroke-dashoffset: ${Math.PI * radius};
            }
            100% {
              transform: rotate(1080deg);
              stroke-dashoffset: ${0.66 * radius};
            }
          }
          circle {
            fill: transparent;
            stroke: currentColor;
            stroke-width: ${thickness};
            stroke-linecap: round;
            stroke-dasharray: ${Math.PI * radius};
            transform-origin: ${0.5 * radius}px ${0.5 * radius}px 0;
            animation: spinner 2s linear infinite;
          }
        `}
      </style>
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${radius} ${radius}`}
        width={`${radius}px`}
        height={`${radius}px`}
      >
        <circle
          cx={radius / 2}
          cy={radius / 2}
          r={radius / 2 - thickness / 2}
        ></circle>
      </svg>
    </>
  );
};

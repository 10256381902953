import '../styles/globals.css';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import NProgress from 'nprogress';
import React, { useEffect } from 'react';
import NextLink from 'next/link';

import { seoConfig } from '@wwtr/config';
import * as gtag from '@wwtr/libs/gtag';
import { GA_TRACKING_ID } from '@wwtr/libs/gtag';
import { useApollo } from '@wwtr/libs/apollo';
import { ApolloProvider } from '@apollo/client';
import {
  Button,
  CONSENT_COOKIE_NAME,
  Container,
  CookieConsent,
  Dimmer,
  Heading,
  MobileMenu,
  SiteFooter,
  SiteHeader,
} from '@wwtr/components/ui';
import {
  DimmerProvider,
  ErrorBoundary,
  MobileMenuProvider,
} from '@wwtr/libs/contexts';
import 'nprogress/nprogress.css';
import { NextPage } from 'next';
import { DefaultLayout } from '@wwtr/components/layout';
import { FavoritesProvider } from '@wwtr/libs/contexts/favorites';
import { RefreshIcon } from '@heroicons/react/outline';
import { getCookie } from 'cookies-next';

type NextPageWithLayout = NextPage & {
  layout?: React.ComponentType;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const AppError = ({ resetErrorBoundary }) => {
  return (
    <div className="flex-1 pt-24">
      <Container>
        <div className="grid lg:grid-cols-8 xl:grid-cols-12">
          <div className="lg:col-start-2 xl:col-start-3 lg:col-span-6 xl:col-span-8 min-h-70vh flex flex-col justify-center items-center space-y-8">
            <Heading
              variant="gray-800"
              level={1}
              className="font-bold text-center"
            >
              Er is een fout opgetreden.
            </Heading>
            <p className="text-gray-700 leading-relaxed lg:text-xl lg:leading-relaxed mb-16 text-center">
              Er is een onbekende fout opgetreden, hiervan is automatisch
              melding gemaakt. Probeer het opnieuw of ga terug naar de
              homepagina.
            </p>
            <div className="flex flex-col md:flex-row">
              <NextLink href={'/'} passHref>
                <Button
                  as="a"
                  appearance="outline"
                  color={'primary'}
                  className="mb-6 md:mb-0 md:mr-4"
                >
                  <span>Terug naar home</span>
                </Button>
              </NextLink>

              <Button
                color={'primary'}
                onClick={() => resetErrorBoundary()}
                withIcon
              >
                <RefreshIcon className="w-6 h-6" />
                <span>Probeer het opnieuw</span>
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const router = useRouter();
  const apolloClient = useApollo(pageProps.initialApolloState);
  const Layout = Component.layout || DefaultLayout; //(children => <>{children}</>);

  useEffect(() => {
    const onRouteChangeStart = () => {
      NProgress.start();
    };

    const onRouteChangeComplete = url => {
      gtag.pageview(url);
      NProgress.done();
    };

    const onRouteChangeError = () => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', onRouteChangeStart);
    router.events.on('routeChangeComplete', onRouteChangeComplete);
    router.events.on('routeChangeError', onRouteChangeError);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      router.events.off('routeChangeComplete', onRouteChangeComplete);
      router.events.off('routeChangeError', onRouteChangeError);
    };
  }, [router.events]);

  const consent = getCookie(CONSENT_COOKIE_NAME);

  return (
    <ApolloProvider client={apolloClient}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            // Define dataLayer and the gtag function.
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}

            gtag('consent', 'default', {
              'analytics_storage': 'denied',
              'ad_storage': 'denied'
            });
            gtag('set', 'ads_data_redaction', true);
            `,
          }}
        />

        {consent === true && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
            gtag('consent', 'update', {
              'ad_storage': 'denied',
              'analytics_storage': 'granted'
            });
          `,
            }}
          />
        )}

        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });`,
          }}
        />
      </Head>
      <DefaultSeo {...seoConfig} />

      <MobileMenuProvider initialState={false}>
        <FavoritesProvider>
          <DimmerProvider initialState={false}>
            <SiteHeader />
            <Layout>
              <CookieConsent />
              <ErrorBoundary FallbackComponent={AppError}>
                <Component {...pageProps} />
              </ErrorBoundary>
            </Layout>
            <SiteFooter />
            <MobileMenu />
            <Dimmer />
          </DimmerProvider>
        </FavoritesProvider>
      </MobileMenuProvider>
    </ApolloProvider>
  );
};

export default App;

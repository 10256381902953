import cn from 'classnames';
import React, { ComponentProps, PropsWithChildren } from 'react';
import { Skeleton } from '@wwtr/components/ui';

export const Heading = ({
  children,
  as,
  level = 1,
  className,
  isLoading = false,
  variant = 'primary',
}: HeadingProps) => {
  const content = (
    <>
      {!isLoading && children}
      {isLoading && <span>loading</span>}
    </>
  );

  const headingSizeMapping = {
    'text-4xl sm:text-5xl md:text-6xl 2xl:text-7xl': level === 1,
    'text-3xl sm:text-4xl md:text-5xl 2xl:text-6xl': level === 2,
    'text-2xl sm:text-3xl md:text-4xl 2xl:text-5xl': level === 3,
    'text-xl sm:text-2xl md:text-3xl 2xl:text-4xl': level === 4,
    'text-lg sm:text-xl md:text-2xl 2xl:text-3xl': level === 5,
    'text-md sm:text-lg md:text-xl 2xl:text-2xl': level === 6,
    'text-normal': level === 7,
  };

  const skeletonHeightMapping = {
    'h-10 sm:h-12 md:h-16 2xl:h-18': level === 1,
    'h-9 sm:h-10 md:h-12 2xl:h-14': level === 2,
    'h-6 md:h-8 lg:h-9': level === 3,
    'h-7 sm:h-8 md:h-9 2xl:h-12': level === 4,
  };

  const HeadingTag = as
    ? (`${as}` as keyof JSX.IntrinsicElements)
    : (`h${level}` as keyof JSX.IntrinsicElements);

  if (isLoading) {
    return (
      <Skeleton className={cn(skeletonHeightMapping, 'w-1/2', className)} />
    );
  }

  return (
    <HeadingTag
      className={cn([`text-${variant}`], headingSizeMapping, className)}
    >
      {content}
    </HeadingTag>
  );
};

export type HeadingProps = ComponentProps<'h1'> &
  PropsWithChildren<{
    as?: string;
    level?: number;
    variant?:
      | 'primary'
      | 'secondary'
      | 'black'
      | 'white'
      | 'hotpink'
      | 'deeporange'
      | 'freshblue'
      | 'powerblue'
      | 'gray-500'
      | 'gray-700'
      | 'gray-800';
    isLoading?: boolean;
  }>;

import { PropsWithChildren } from 'react';
import NextLink from 'next/link';
import { Form, Formik } from 'formik';
import {
  Button,
  FormField,
  FormInput,
  FormSelect,
  Link,
  Skeleton,
} from '@wwtr/components/ui';
import * as Yup from 'yup';
import { HomepageOrganizationMapCategoriesQuery } from '@wwtr/generated';
import { useQuery } from '@apollo/client';
import HOMEPAGE_ORGANIZATION_MAP_CATEGORIES_QUERY from '@wwtr/gql/queries/homepage-organization-map-categories.gql';
import { getNetworkStatusDetails } from '@wwtr/libs/apollo/utils';
import { useRouter } from 'next/router';

export type OrganizationsMapSearchBarProps = PropsWithChildren<{
  isLoading: boolean;
}>;

const searchValidationSchema = Yup.object().shape({
  q: Yup.string().max(30, 'Zoekterm is te lang'),
});

const OrganizationsMapSearchBar = ({
  children,
  isLoading,
  ...rest
}: OrganizationsMapSearchBarProps) => {
  const router = useRouter();

  const { data: selectData, networkStatus: selectDataNetworkStatus } =
    useQuery<HomepageOrganizationMapCategoriesQuery>(
      HOMEPAGE_ORGANIZATION_MAP_CATEGORIES_QUERY,
      {
        notifyOnNetworkStatusChange: true,
      }
    );

  const { isLoading: selectDataIsLoading, isReady: selectDataIsReady } =
    getNetworkStatusDetails(selectDataNetworkStatus);

  if (selectDataIsLoading) {
    return (
      <div className="grid grid-cols-1 gap-6">
        <Skeleton className="w-full h-14"></Skeleton>
        <Skeleton className="w-full h-14"></Skeleton>
        <div className="flex flex-row items-center w-full space-x-8">
          <Skeleton className="w-72 h-14 rounded-full" />
          <Skeleton className="w-44 h-4" />
        </div>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        q: '',
        organizationCategory: '',
      }}
      validationSchema={searchValidationSchema}
      onSubmit={values => {
        const searchQuery = {
          q: values.q,
          c: values.organizationCategory,
        };

        router.push(
          { pathname: 'zoek/organisaties', query: searchQuery },
          null,
          {
            shallow: true,
          }
        );
      }}
    >
      {({ handleChange, handleBlur, values }) => (
        <Form className="grid grid-cols-1 gap-6">
          <FormField name="q" htmlFor="q">
            <FormInput
              name="q"
              id="q"
              placeholder="Zoekterm..."
              variant="md"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.q}
            />
          </FormField>
          {/*
          <FormField name="themes" htmlFor="themes">
            <FormSelect name="themes" variant="md" id="city">
              <option value="">Onderwerpen</option>
              {selectData?.serviceCategories.map(category => (
                <option key={`serviceCat_${category.id}`} value={category.id}>
                  {category.title}
                </option>
              ))}
            </FormSelect>
          </FormField>
          */}
          <FormField
            name="organizationCategory"
            htmlFor="organization_category"
          >
            <FormSelect
              name="organizationCategory"
              variant="md"
              id="organization_category"
            >
              <option value="">Soort organisatie</option>
              {selectData?.organizationTypes.map(category => (
                <option
                  key={`organizationType_${category.id}`}
                  value={category.id}
                >
                  {category.title}
                </option>
              ))}
            </FormSelect>
          </FormField>
          <div className="flex flex-row items-center space-x-8">
            <Button
              type={'submit'}
              appearance="outline"
              color="secondary"
              disabled={false}
              className={'block w-1/2'}
              size="md"
            >
              Zoek
            </Button>
            <NextLink href="zoek/organisaties">
              <Link color="white" withArrow customUnderline>
                Uitgebreid zoeken
              </Link>
            </NextLink>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default OrganizationsMapSearchBar;

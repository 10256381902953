import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

export const Badge = ({ children, className }: BadgeProps) => {
  return <span className={cn('rounded-full px-2', className)}>{children}</span>;
};

export type BadgeProps = PropsWithChildren<{
  className?: string;
}>;

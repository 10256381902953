import { take, truncate, words } from 'lodash';

export const clip = (text, size = 3) => {
  const wordsArray = words(text);
  const shouldClip = wordsArray.length > size;
  const sentence = shouldClip ? take(wordsArray, size).join(' ') : text;
  return shouldClip ? `${sentence}...` : sentence;
};

export const getFirstParagraph = (
  text: string,
  truncateLength: number
): string => {
  return truncate(text.split('</p>')[0].replace('<p>', ''), {
    length: truncateLength,
  });
};

import React, { PropsWithChildren } from 'react';
import styles from './RichText.module.scss';
import { DynamicRouterLinks } from '@wwtr/components/ui';

export const RichText = ({ content }: RichTextProps) => (
  <DynamicRouterLinks>
    <div className={styles.root} dangerouslySetInnerHTML={{ __html: content }}></div>
  </DynamicRouterLinks>
);

export type RichTextProps = PropsWithChildren<{
  content: string
}>;

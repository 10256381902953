import cn from 'classnames';
import React from 'react';

import { useDimmer } from '@wwtr/libs/contexts';

export const Dimmer = () => {
  const { isDimmed } = useDimmer();

  return (
    <div
      className={cn(
        'fixed t-0 left-0 right-0 bottom-0 bg-black bg-opacity-80 w-full h-full z-40 transition-opacity duration-300 pointer-events-none',
        {
          'opacity-0': !isDimmed,
          'opacity-100': isDimmed,
        }
      )}
    ></div>
  );
};

import NextLink from 'next/link';

import * as gtag from '@wwtr/libs/gtag';
import { Logo } from '@wwtr/components/ui';
import { memo } from 'react';

export type SiteHeaderLogoProps = {
  className?: string;
};

const SiteHeaderLogoComponent = ({ className }: SiteHeaderLogoProps) => {
  return (
    <NextLink href="/">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid */}
      <a
        className={className}
        title="Terug naar home"
        onClick={() => {
          gtag.event({
            action: 'click_on_logo',
            category: 'clicks',
          });
        }}
      >
        <Logo className="w-32" />
        <h1 className="sr-only">Wegwijs Twenterand</h1>
      </a>
    </NextLink>
  );
};

export const SiteHeaderLogo = memo(SiteHeaderLogoComponent);

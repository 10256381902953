import { Body_SlideShowBlock_BlockType } from '@wwtr/generated';

export type ComponentBodySlideShowProps = Body_SlideShowBlock_BlockType;

export const ComponentBodySlideShow = ({
  title,
  files,
}: ComponentBodySlideShowProps) => {
  return (
    <figure className="-mx-8">
      {files.map((file, index) => (
        <img key={`image_${index}`} src={file.url} alt={file.title} />
      ))}
      <figcaption className="px-8 pt-2 text-gray-300 text-sm">
        {title}
      </figcaption>
    </figure>
  );
};

import React, { PropsWithChildren } from 'react';
import NextLink from 'next/link';

import { Container } from '../Container';
import { Themes_Theme_Entry } from '@wwtr/generated';
import { Tile } from '../Tile';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { Heading } from '@wwtr/components/ui';
import { getIcon } from '@wwtr/libs/utils';

export type ThemeCategoryProps = PropsWithChildren<{
  isLoading?: boolean;
  entry: Themes_Theme_Entry;
}>;

export const ThemeCategory = ({
  children,
  isLoading = true,
  entry,
  ...props
}: ThemeCategoryProps) => {
  return (
    <Container>
      <div className="w-full lg:w-7/12 xl:w-6/12">
        <div
          className="mb-8 text-3xl leading-normal text-gray-400"
          dangerouslySetInnerHTML={{
            __html: entry?.lead,
          }}
        />
        <nav className="grid gap-6 mb-16">
          <h2 className="sr-only">Onderwerpen</h2>
          {entry?.children.map((child: Themes_Theme_Entry) => (
            <NextLink
              key={`child_id_${child.id}`}
              href={`/${child.uri}`}
              passHref
            >
              <a>
                <Tile
                  variant="white"
                  hasShadow
                  padded
                  size="md"
                  as="article"
                  hoverScale
                  className="flex flex-row items-center group space-x-6"
                >
                  <figure className="flex-none self-start">
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      alt={child.title}
                      src={getIcon(child)}
                      className="w-12"
                    />
                  </figure>
                  <div className="flex-auto">
                    <Heading
                      level={4}
                      as="h3"
                      className="font-light group-hover:underline"
                      variant="powerblue"
                    >
                      {child.title}
                    </Heading>
                    <div
                      className="hidden md:block"
                      dangerouslySetInnerHTML={{
                        __html: child?.lead,
                      }}
                    />
                  </div>
                  <ArrowRightIcon className="flex-none w-8 text-gray-200 group-hover:text-gray-400" />
                </Tile>
              </a>
            </NextLink>
          ))}
        </nav>
      </div>
    </Container>
  );
};

export const initialState = {
  cart: [],
  showCart: false,
  updatingCart: false,
  searchOpen: false,
  notifications: [],
  freeShippingPrice: undefined,
};

// SEO Texts
export const SITE_TITLE = 'Wegwijstwenterand.nl';
export const META_KEYWORDS =
  'twenterand, gemeente, welzijn, sociale, kaart, hulpaanbod, voorzieningen, organisaties, bedrijven, voorlichting, activiteiten';
export const META_DESCRIPTION =
  'Wegwijs Twenterand is de sociale kaart die overzicht geeft van organisaties die hulp, zorg, advies en activiteiten bieden in de gemeente Twenterand.';

export const TITLE_SEPARATOR = ' - ';

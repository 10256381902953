import cn from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';

import { Container, Heading, Link, SiteNavSearch } from '@wwtr/components/ui';
import { HeartIcon, XIcon } from '@heroicons/react/outline';
import { useDimmer, useMobileMenu } from '@wwtr/libs/contexts';
import NextLink from 'next/link';

export const MobileMenu = () => {
  const { isMenuOpen, toggleMenu } = useMobileMenu();
  const { toggleDimmed } = useDimmer();
  const closeButtonRef = useRef<null | HTMLButtonElement>(null);

  const toggleAll = () => {
    toggleMenu();
    toggleDimmed();
  };

  useEffect(() => {
    if (isMenuOpen) {
      closeButtonRef.current?.focus();
    }
  }, [isMenuOpen]);

  const onSubmitHandler = useCallback(() => {
    toggleAll();
  }, [toggleAll]);

  const menuItems = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'Onderwerpen',
      url: '/een-vraag-over/',
    },
    {
      title: 'Zoek in uw regio',
      url: '/zoek/organisaties/',
    },
  ];

  return (
    <div
      className={cn(
        'fixed t-0 left-0 right-0 bottom-0 bg-powerblue w-full h-full z-50 transition-transform duration-300 transform',
        {
          'translate-x-full': !isMenuOpen,
          'translate-x-0': isMenuOpen,
        }
      )}
    >
      <div className="h-full overflow-auto pb-16">
        <Container>
          <div className="flex items-center justify-end h-24">
            <button
              className="inline-flex items-center p-2 text-lg text-white no-underline focus:outline-none focus:underline"
              onClick={() => toggleAll()}
              ref={closeButtonRef}
            >
              <span>sluiten</span>
              <XIcon className="h-6 w-6 ml-2" />
            </button>
          </div>
          <nav className="flex flex-col space-y-8">
            <Heading level={2} variant="white">
              Menu
            </Heading>
            <SiteNavSearch onSubmit={onSubmitHandler} />
            <ul className="divide-y divide-white divide-solid divide-opacity-10">
              {menuItems.map((item, index) => {
                return (
                  <li className="py-4" key={`mobile_nav_${index}`}>
                    <NextLink href={item.url}>
                      <Link
                        href={item.url}
                        color="white"
                        withArrow
                        onClick={toggleAll}
                      >
                        {item.title}
                      </Link>
                    </NextLink>
                  </li>
                );
              })}
            </ul>
            <ul className="divide-y divide-white divide-solid divide-opacity-10">
              <li className="py-4">
                <NextLink href="/contact">
                  <Link
                    href="/contact"
                    color="white"
                    withArrow
                    onClick={toggleAll}
                  >
                    Contact
                  </Link>
                </NextLink>
              </li>
              <li className="py-4">
                <NextLink href="/favorieten">
                  <Link
                    href="/favorieten"
                    containsIcon
                    iconSize={8}
                    color="white"
                    onClick={toggleAll}
                  >
                    <HeartIcon className="w-6 text-hotpink" />
                    <span>Favorieten</span>
                  </Link>
                </NextLink>
              </li>
            </ul>
          </nav>
        </Container>
      </div>
    </div>
  );
};

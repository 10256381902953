import React from 'react';
import { Button } from '@wwtr/components/ui';
import { usePagination, UsePaginationProps } from '@wwtr/libs/hooks';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/solid';

export type PaginationProps = UsePaginationProps & {
  isLoading: boolean;
};

const PaginationItemPage = ({ page, selected, ...other }) => (
  <Button
    size="sm"
    color="powerblue"
    appearance={selected ? 'solid' : 'outline'}
    {...other}
  >
    {page}
  </Button>
);

const PaginationItem = ({ type, page, selected, ...other }) => {
  switch (type) {
    case 'start-ellipsis':
    case 'end-ellipsis':
      return <div className="px-1">&hellip;</div>;
    case 'first':
    case 'last':
      return (
        <Button size="sm" color="powerblue" appearance="outline" {...other}>
          {type === 'first' ? (
            <ChevronDoubleLeftIcon className="inline-block w-4 leading-normal" />
          ) : (
            <ChevronDoubleRightIcon className="inline-block w-4 leading-normal" />
          )}
        </Button>
      );
    case 'previous':
    case 'next':
      return (
        <Button size="sm" color="powerblue" appearance="outline" {...other}>
          {type === 'next' ? 'Volgende' : 'Vorige'}
        </Button>
      );
    case 'page':
      return <PaginationItemPage page={page} selected={selected} {...other} />;
    default:
      return null;
  }
};

export const Pagination = ({ isLoading, ...props }: PaginationProps) => {
  const { items } = usePagination(props);

  return isLoading ? null : (
    <ul className="flex flex-row items-center justify-between px-8 py-4">
      {items.map((page, pageIndex) => (
        <li key={`pagination_item_${pageIndex}`}>
          <PaginationItem {...page} />
        </li>
      ))}
    </ul>
  );
};

import React, { PropsWithChildren } from 'react';
import NextLink from 'next/link';
import { Heading } from '../Heading';
import { Tile } from '../Tile';
import {
  CategoryInterface,
  OrganizationLocations_OrganizationLocation_Entry,
  ThemeFacilityEntryFragment,
  ThemeOrganizationEntryFragment,
  Themes_Theme_Entry,
} from '@wwtr/generated';
import { Link } from '../Link';
import { lowerFirst } from 'lodash';
import {
  Button,
  Carousel,
  Container,
  ListLimit,
  ListLimitMoreComponentProps,
} from '@wwtr/components/ui';
import { ExternalLink, Globe, MapPin, Phone } from 'react-feather';
import { getFirstParagraph } from '@wwtr/libs/utils';
import { ArrowRightIcon } from '@heroicons/react/solid';

export type ThemeOrganizationsProps = {
  isLoading?: boolean;
  entry?: Themes_Theme_Entry;
  facilities: ThemeFacilityEntryFragment[];
  organizations: ThemeOrganizationEntryFragment[];
};

type CategoryHeadProps = PropsWithChildren<{
  title: string;
}>;

const CategoryHead = ({ title, children }: CategoryHeadProps) => {
  return (
    <Container>
      <div className="mb-16 text-center">
        <Heading
          level={2}
          variant="gray-800"
          className="mb-4 text-center font-light"
        >
          <a id="aanbod">{title}</a>
        </Heading>
        <p className="mx-auto w-4/5 text-lg text-gray-700">{children}</p>
      </div>
    </Container>
  );
};

type CategorySubHeadProp = PropsWithChildren<{
  category: CategoryInterface;
}>;

const CategorySubHead = ({ category, children }: CategorySubHeadProp) => {
  return (
    <div key={category.id} className="py-8">
      <Container>
        <Heading
          level={3}
          className="mb-4 lg:mb-8 font-light"
          variant="gray-700"
        >
          {category.title}
        </Heading>
      </Container>
      <Container padded={false} className="lg:px-8">
        <Carousel>{children}</Carousel>
      </Container>
    </div>
  );
};

const GoToMoreFacilities = ({ total, uri }: ListLimitMoreComponentProps) => (
  <Tile
    className="flex flex-col items-center justify-center h-full px-12"
    variant="primary"
    size="md"
    hasShadow
  >
    <NextLink href={`/${uri}`} passHref>
      <Button as="a" appearance="outline" color="white" withIcon>
        <span>Nog {total} resultaten</span>
        <ArrowRightIcon className="w-6 h-6" />
      </Button>
    </NextLink>
  </Tile>
);

const ThemeOrganizations = ({
  isLoading = true,
  entry,
  facilities,
  organizations,
}: ThemeOrganizationsProps) => {
  return (
    <div>
      <div id={'services'} className="mb-24">
        <CategoryHead title={`Hulp voor ${lowerFirst(entry?.title)}`}>
          <span>
            Bekijk hieronder wat het hulpaanbod is binnen de gemeente Twenterand
            voor {entry?.title}. Vind u niet wat u zoekt?
          </span>{' '}
          <NextLink href={'/contact'}>
            <Link href={'/contact'}>Neem dan contact met ons op</Link>
          </NextLink>
          .
        </CategoryHead>
        {entry.serviceCategories.map(category => {
          const categoryEntries = facilities.filter(item =>
            item.serviceType.some(item => item.id === category.id)
          );

          if (categoryEntries.length > 0) {
            return (
              <CategorySubHead key={category.id} category={category}>
                <ListLimit
                  className="grid grid-flow-col lg:grid-flow-row lg:grid-cols-3 gap-8"
                  total={categoryEntries.length}
                  limit={6}
                  flexible={3}
                  showMoreComponent={GoToMoreFacilities}
                  showMoreUri={`zoek/aanbod/?t=${category.id}`}
                >
                  {categoryEntries.splice(0, 9).map((item, index) => (
                    <Tile
                      key={`facility_${index}`}
                      className="flex flex-col h-full"
                      variant="white"
                      size="md"
                      hasShadow
                      hoverScale
                    >
                      <Heading level={5} as="h4" className="mb-4 font-semibold">
                        <NextLink href={`/${item.uri}`}>
                          <Link href={`/${item.uri}`} noWrap={false}>
                            {item.title}
                          </Link>
                        </NextLink>
                      </Heading>
                      {item.organization.length > 0 && (
                        <p className="text-gray-700 text-sm mb-2">
                          Aangeboden door:{' '}
                          {
                            (
                              item
                                .organization[0] as ThemeOrganizationEntryFragment
                            ).title
                          }
                        </p>
                      )}
                      <p
                        className="text-lg mb-4"
                        dangerouslySetInnerHTML={{
                          __html: getFirstParagraph(item.description, 160),
                        }}
                      ></p>
                      <div>
                        <NextLink href={`/${item.uri}`}>
                          <Link href={`/${item.uri}`} customUnderline withArrow>
                            Lees meer
                          </Link>
                        </NextLink>
                      </div>
                    </Tile>
                  ))}
                </ListLimit>
              </CategorySubHead>
            );
          } else {
            return null;
          }
        })}
      </div>

      <div id={'organizations'}>
        <CategoryHead title={`Organisaties voor ${lowerFirst(entry?.title)}`}>
          <span>
            Bekijk hieronder welke organisaties binnen de gemeente Twenterand u
            kunnen helpen bij {entry?.title}. Vind u niet wat u zoekt?
          </span>{' '}
          <NextLink href={'/contact'}>
            <Link href={'/contact'}>Neem dan contact met ons op</Link>
          </NextLink>
          .
        </CategoryHead>
        <Container padded={false} className="lg:px-8">
          <Carousel>
            <ul className="grid grid-flow-col lg:grid-flow-row lg:grid-cols-3 gap-8">
              {organizations.map((item, index) => (
                <li
                  key={`organization_${index}`}
                  className="w-80vw sm:w-60vw md:w-40vw lg:w-auto"
                >
                  <Tile
                    className="flex flex-col h-full"
                    variant="white"
                    size="md"
                    hasShadow
                    hoverScale
                  >
                    <Heading level={5} className="mb-4 font-semibold">
                      <NextLink href={`/${item.uri}`}>
                        <Link href={`/${item.uri}`} noWrap={false}>
                          {item.title}
                        </Link>
                      </NextLink>
                    </Heading>
                    {(
                      item?.locations as OrganizationLocations_OrganizationLocation_Entry[]
                    ).map((location, index) => {
                      if (index === 0) {
                        return (
                          <ul
                            key={`location_data_${index}`}
                            className="space-y-2"
                          >
                            {location.phone && (
                              <li className="pl-7">
                                <Phone className="inline -ml-7 mr-2 w-5" />
                                <span>{location.phone}</span>
                              </li>
                            )}
                            {location.website && (
                              <li className="pl-7 flex items-center">
                                <Globe className="inline -ml-7 mr-2 w-5" />
                                <Link href={location.website} target="_blank">
                                  <span>{new URL(location.website).host}</span>
                                  <ExternalLink className="inline ml-1 w-4 align-top" />
                                </Link>
                              </li>
                            )}
                            {location.visitAddressHide === false &&
                              location.visitAddress.parts.address && (
                                <li className="pl-7 flex">
                                  <MapPin className="inline -ml-7 mr-2 w-5" />
                                  <address className="inline-block">
                                    {location?.visitAddress.parts.address}{' '}
                                    {location?.visitAddress.parts.number}
                                    <br />
                                    {location?.visitAddress.parts.postcode}
                                    <br />
                                    {location?.visitAddress.parts.city}
                                  </address>
                                </li>
                              )}
                          </ul>
                        );
                      }
                    })}
                  </Tile>
                </li>
              ))}
            </ul>
          </Carousel>
        </Container>
      </div>
    </div>
  );
};

export default ThemeOrganizations;

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Container, SiteNav, SiteNavSearch } from '@wwtr/components/ui';
import { useBreakpoints } from '@wwtr/libs/hooks';
import { Transition } from '@headlessui/react';
import { SiteHeaderLogo } from './SiteHeaderLogo';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { useDimmer, useMobileMenu } from '@wwtr/libs/contexts';
import cn from 'classnames';

export const SiteHeader = ({ children }: SiteHeaderProps) => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const { isTabletPortrait, isDesktop, isMobile } = useBreakpoints();
  const { isMenuOpen, toggleMenu } = useMobileMenu();
  const { toggleDimmed } = useDimmer();
  const menuButtonRef = useRef<null | HTMLButtonElement>(null);

  const [scrolled, setScrolled] = useState(false);

  const onSearchHandler = useCallback(() => setIsSearchActive(true), []);
  const onBlurHandler = useCallback(() => setIsSearchActive(false), []);

  useEffect(() => {
    const handleScroll = e => {
      setScrolled(window.scrollY > 16);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isMenuOpen) {
      menuButtonRef.current?.focus();
    }
  }, [isMenuOpen]);

  return (
    <header
      role="banner"
      className={cn(
        'w-full h-auto fixed bg-white z-40 print:relative print:shadow-none',
        {
          'shadow-xl': scrolled,
        }
      )}
    >
      <Container
        fluid={true}
        className={cn(
          'h-24 w-full flex flex-row justify-between items-center md:items-center'
        )}
      >
        <SiteHeaderLogo className="mr-8 focus:outline-black" />

        {isMobile && (
          <button
            onClick={() => {
              toggleMenu();
              toggleDimmed();
            }}
            className="print:hidden inline-flex items-center p-2 text-lg text-gray-800 no-underline focus:outline-none focus:underline"
            ref={menuButtonRef}
          >
            <span>menu</span>
            {isMenuOpen ? (
              <XIcon className="h-6 w-6 ml-2" />
            ) : (
              <MenuIcon className="h-6 w-6 ml-2" />
            )}
          </button>
        )}

        {(isTabletPortrait || isDesktop) && (
          <div className="print:hidden flex-grow relative z-10 h-24">
            <Transition
              appear={false}
              show={!isSearchActive}
              enter="transition duration-200"
              enterFrom="opacity-0 -translate-y-16"
              enterTo="opacity-100 translate-y-0"
              leave="transition transform duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-16"
              className="h-full absolute left-0 top-0 w-full transform"
            >
              <SiteNav onSearch={onSearchHandler} />
            </Transition>
            <Transition
              appear={false}
              show={isSearchActive}
              enter="transition duration-200"
              enterFrom="opacity-0 translate-y-8"
              enterTo="opacity-100 translate-y-0"
              leave="transition duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-8"
              className="h-full absolute left-0 w-full transform"
            >
              <SiteNavSearch onBlur={onBlurHandler} />
            </Transition>
          </div>
        )}
      </Container>

      {children}
    </header>
  );
};

export type SiteHeaderProps = React.PropsWithChildren<{}>;

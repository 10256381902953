import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';

export type DynamicRouterLinksProps = {
  children: React.ReactNode;
};

const isMarkedAsExternalLink = (a: HTMLAnchorElement): boolean => {
  return (
    a.target === '_blank' &&
    a.rel.includes('noreferrer') &&
    a.rel.includes('noopener')
  );
};

const getRelativeUrl = (a: HTMLAnchorElement): string | void => {
  const href = a.getAttribute('href');

  if (href) {
    const hrefUrl = new URL(a.getAttribute('href'));

    // Convert but keep all query params and stuff
    return hrefUrl.toString().substring(hrefUrl.origin.length);
  }
};

const DynamicRouterLinks = ({ children }: DynamicRouterLinksProps) => {
  const sourceEl = useRef(null);
  const router = useRouter();

  useEffect(() => {
    sourceEl.current.querySelectorAll('a').forEach(a => {
      if (!isMarkedAsExternalLink(a)) {
        const relativeUrl = getRelativeUrl(a);

        if (relativeUrl && relativeUrl !== '/') {
          a.setAttribute('href', relativeUrl);

          a.addEventListener('click', event => {
            event.preventDefault();
            const nextRoute = a.getAttribute('href');
            router.push(nextRoute).then(() => window.scrollTo(0, 0));
          });
        }
      }
    });
  }, [router.asPath]);

  return <div ref={sourceEl}>{children}</div>;
};

export default DynamicRouterLinks;

import React, { memo, PropsWithChildren } from 'react';
import { useQuery } from '@apollo/client';
import {
  HomepageOrganizationMapLocationsQuery,
  OrganizationLocations_OrganizationLocation_Entry,
  Organizations_Organization_Entry,
} from '@wwtr/generated';
import HOMEPAGE_ORGANIZATION_MAP_LOCATIONS_QUERY from '@wwtr/gql/queries/homepage-organization-map-locations.gql';
import { getNetworkStatusDetails } from '@wwtr/libs/apollo/utils';

export type OrganizationsMapProps = PropsWithChildren<{}>;

const center = {
  lat: 52.4395737,
  lng: 6.5126433,
};

const OrganizationsMap = ({}: OrganizationsMapProps) => {
  const { data: locationData, networkStatus: locationDataNetworkStatus } =
    useQuery<HomepageOrganizationMapLocationsQuery>(
      HOMEPAGE_ORGANIZATION_MAP_LOCATIONS_QUERY,
      {
        notifyOnNetworkStatusChange: true,
      }
    );

  const { isReady: locationsIsReady = false } = getNetworkStatusDetails(
    locationDataNetworkStatus
  );

  const renderMap = () => {
    const markers = locationData.organizations.map(
      (organization: Organizations_Organization_Entry) => {
        return (
          organization.locations as OrganizationLocations_OrganizationLocation_Entry[]
        )
          .filter(location => !!location.visitAddress.lat)
          .map(
            location =>
              `${location.visitAddress.lat},${location.visitAddress.lng}`
          );
      }
    );

    return (
      <figure className="absolute top-0 right-0 bottom-0 left-0">
        <img
          src={`https://maps.googleapis.com/maps/api/staticmap?center=${
            center.lat
          },${center.lng}&markers=color:0x2a378d|size:tiny|${markers.join(
            '|'
          )}&zoom=11&size=640x640&scale=2&maptype=roadmap&key=AIzaSyALAoCzT99hIXU-2VR94P8Bdhz2xp4IWXY`}
          title=""
          className="w-full h-full object-cover"
        />
      </figure>
    );
  };

  return locationsIsReady ? renderMap() : null;
};

export default memo(OrganizationsMap);

import NextLink from 'next/link';
import { Button, Heading, Link, Tile } from '@wwtr/components/ui';
import { ArrowRightIcon, HeartIcon } from '@heroicons/react/solid';
import { Organizations_Organization_Entry } from '@wwtr/generated';

export type OrganizationListingItemProps = {
  entry: Organizations_Organization_Entry;
  isLoading: boolean;
};

export const OrganizationListingItem = ({
  entry,
  isLoading,
}: OrganizationListingItemProps) => {
  return (
    <Tile
      padded
      size="md"
      variant="white"
      hasShadow
      className="space-y-3"
      hoverScale
    >
      <div className="flex items-start justify-between">
        <Heading level={3}>
          <Link color="powerblue" noWrap={false}>
            Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
          </Link>
        </Heading>
        <button className="text-gray-400 hover:text-hotpink ml-4">
          <HeartIcon className="w-7" />
        </button>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-8 xl:col-span-9 space-y-4">
          <p className="text-base md:text-lg">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae
            elit libero, a pharetra augue. Aenean eu leo quam. Pellentesque
            ornare sem lacinia quam venenatis vestibulum. Lorem ipsum dolor sit
            amet, consectetur adipiscing elit.
          </p>
          <p className="text-sm text-gray-400">
            Laan van Puntenburg 15J, Almelo
          </p>
        </div>
        <div className="col-span-12 lg:col-span-4 xl:col-span-3 flex items-end justify-end">
          <div className="text-right">
            <Button color="primary" appearance="outline" size="sm" withIcon>
              <span>Meer informatie</span>
              <ArrowRightIcon className="w-4" />
            </Button>
          </div>
        </div>
      </div>
    </Tile>
  );
};
